@import "var.conf";
@import "base.css";
@import "common.css";

/*---------------------------------
Page CSS 2018.05
CSS 担当者名 Document 2018.05
Last Update 2018.05 担当者名
---------------------------------*/
@media print,
screen and (min-width: 768px) {

  /*
  トップ、voice共通　トップ、blog共通
  -------------------------------------*/
  #Main{
    .contBox{
      .voiceBox{
        margin:0 65px;
        padding:30px;
        background:#fff;
        border-radius:10px;
        .img{
          width:230px;
          float:left;
        }
        .txt{
          width:560px;
          float:right;
          padding-left: 39px;
          padding-top:7px;
          letter-spacing: .05em;
          .details{
            display:inline-block;
            font-size:14px;
            line-height:26px;
            color:#38aed5;
            letter-spacing:.05em;
            border-bottom:1px dashed #38aed5;
          }
          h4{
            font-size:19px;
            letter-spacing:.05em;
            color:#38aed5;
            font-weight:700;
            line-height:32px;
            margin-top: 7px;
          }
          p{
            margin:18px 0 0;
            line-height:26px;
          }
        }
        &+.contSubBox{
          margin-top:40px;
        }
      }
      .entryBlogWrap{
        letter-spacing:-.4em;
        text-align:left;
        >p{
          letter-spacing:.05em;
        }
        dl{
          vertical-align:top;
          letter-spacing:normal;
          width:300px;
          display:inline-block;
          margin-right:40px;
          margin-top:62px;
          text-align:left;
          &:nth-of-type(3n){
            margin-right:0;
          }
          .img{
            a{
              display:block;
              &:hover{
                opacity:.8;
              }
              img {
                height: auto;
              }
            }
          }
          .category{
            letter-spacing: -.4em;
            li{
              margin-top:16px;
              margin-right:8px;
              display:inline-block;
              a{
                display:block;
                padding:12px 16px;
                line-height:1;
                font-size:14px;
                font-weight:700;
                border-radius:100vh;
                background:#f1f1f1;
                letter-spacing: .05em;
                &:hover{
                  text-decoration:none;
                  background:#d5ebf3;
                  border-radius:100vh;
                }
              }
            }
          }
          .date{
            margin-top:10px;
          }
          dt{
            margin-top:9px;
            a{
              font-weight:700;
              color:#343434;
              letter-spacing:.05em;
            }
          }
        }
      }
    }
    #SubBlogBox{
      text-align:left;
      padding:70px 0 75px;
      dl{
        display:inline-block;
        vertical-align:middle;
        width:100%;
        dt{
          display:inline-block;
          vertical-align:middle;
          position:relative;
          &:after{
            content: "";
            position: absolute;
            bottom: 3px;
            right: -23px;
            width: 13px;
            height: 6px;
            background: #38aed5;
          }
        }
        dd{
          display:inline-block;
          vertical-align:middle;
          margin-left:66px;
          // margin-top: 8px;
          ul{
            width:785px;
            li{
              display:inline-block;
              &+li{
                margin-left:33px;
              }
              &:nth-of-type(3){
                margin-right:300px;
              }
              &:nth-of-type(4),&:nth-of-type(8){
                margin-left:0;
              }
              a{
                font-size:16px;
                line-height:36px;
                color:#24386b;
                font-weight:700;
                letter-spacing: .05em;
                &:after{
                  content: "";
                  position: relative;
                  display: inline-block;
                  margin-left: 16px;
                  width: 9px;
                  height: 16px;
                  background-repeat: no-repeat;
                  background-position: center center;
                  background-image: url(../img/arrow_round.png);
                  background-size: 9px auto;
                  vertical-align:middle;
                }
              }
            }
          }
        }
      }
    }
  }


  /*
  トップページ
  -------------------------------------*/
  #Page.pageIndex {
    #MainImg{
      background:#fff;
      padding:0;
      #MainImgInner{
        height:592px;
        .innerBasic{
          height:100%;
          max-width:1064px;
          width:auto;
        }
        h2{
          background-color:#24386b;
          background-image:url(../img/top/pagettl_r.png);
          background-size:260px auto;
          background-position:left 5px center;
          width:348px;
          height:348px;
          font-size:26px;
          line-height:58px;
          bottom:-26px;
          top:auto;
          right: 10px;
          padding-top:88px;
          letter-spacing:0.1em;
        }
      }
    }
    #Main{
      .topMenu{
        padding-top:44px;
        padding-bottom:42px;
        border-bottom:1px solid #d6e3e7;
        ul{
          text-align:center;
          li{
            display:inline-block;
            vertical-align:bottom;
            &+li{
              margin-left:149px;
            }
            a{
              display:inline-block;
              min-width:146px;
              color:#24386b;
              font-size:20px;
              font-weight:700;
              &:before{
                content:"";
                display:block;
                width:100%;
                height:150px;
                background-position:top center;
                background-repeat:no-repeat;
                background-size:146px auto;
                background-image:url(../img/beginner/illust1.png);
              }
              &:hover{
                text-decoration:none!important;
                &:before{
                  opacity:0.7;
                }
              }
            }
            &:nth-child(2){
              margin-left:114px;
              a{
                min-width:181px;
                &:before{
                  height:138px;
                  background-size:181px auto;
                  background-image:url(../img/illust01.png);
                }
              }
            }
            &:nth-child(3){
              a{
                min-width:180px;
                &:before{
                  height:128px;
                  background-size:180px auto;
                  background-image:url(../img/illust02.png);
                }
              }
            }
          }
        }
      }
      .contBox{
        .border_title{
          margin-bottom:20px;
        }
        p{
          text-align:center;
          font-size:20px;
          line-height:32px;
        }
        .linkBtn{
          text-align:center;
        }
      }
      #ContBox01{
        padding:114px 0 0;
        .border_title{
          margin-bottom:21px;
        }
        .topInner{
          max-width:1280px;
          overflow:hidden;
          margin:64px auto 0;
          padding-bottom:86px;
          position:relative;
          .innerBasic{
            position:relative;
          }
          .trainerImg{
            width:100%;
            position:absolute;
            left:-100px;
            top:0;
            .innerBox{
              width:554px;
              position:relative;
              &:before{
                content:"";
                display:block;
                position:absolute;
                width:445px;
                height:430px;
                left:-40px;
                bottom:-100px;
                background:#d5ebf3;
              }
            }
            img{
              width:554px;
              height:auto;
              position:relative;
            }
            dl{
              width:149px;
              height:112px;
              position:absolute;
              bottom:0;
              right:0;
              background:#fff;
              text-align:center;
              padding-top:26px;
              dt{
                font-size:12px;
                letter-spacing: .05em;
              }
              dd{
                font-size:15px;
                font-weight:700;
                margin-top: 3px;
                letter-spacing: .01em;
                span{
                  display:block;
                  font-size:11px;
                }
              }
            }
          }
          .txt{
            width:477px;
            margin-left:auto;
            text-align:left;
            padding-top:25px;
            p,.txtBox li{
              font-size:16px;
              line-height:32px;
              text-align:left;
              margin-bottom:0;
              letter-spacing:0.05em;
            }
            .txtBox{
              margin-top:10px;
              margin-left:6px;
              li{
                position:relative;
                padding-left:10px;
                &+li{
                }
                &:before{
                  content:"";
                  display:block;
                  width:4px;
                  height:4px;
                  border-radius:50px;
                  background:#343434;
                  position:absolute;
                  top:0.8em;
                  left:0;
                }
              }
              &+p{
                margin-top:8px;
              }
            }
            .linkBox{
              text-align:right;
              margin-top:20px;
              .roundLinkBtn{
                margin-left:0;
                &+.roundLinkBtn{
                  margin-left:30px;
                }
              }
            }
          }
        }
      }
      #ContBox02{
        .cf{
          margin-top:43px;
        }
        .recommend{
          width:540px;
          float:left;
          border-radius:10px;
          background:#f7f7f7;
          padding:31px 50px 40px;
          position:relative;
          z-index:0;
          dt{
            margin:0 auto 22px;
            border-bottom:1px solid #f2f2f2;
            font-size:22px;
            font-weight:700;
            color:#38aed5;
            text-align:center;
            position:relative;
            background:#f7f7f7;
            padding-bottom:6px;
            &:before{
              content:"";
              position:absolute;
              top:50%;
              bottom:0;
              left:20px;
              right: 20px;
              margin:auto;
              border-radius:100px / 10px;
              box-shadow:0 5px 15px -5px rgba(0, 0, 0, 0.3);
              z-index:-1;
            }
          }
          dd{
            font-size:17px;
            font-weight:700;
            ul{
              margin-left:17px;
              li{
                padding-left:27px;
                position:relative;
                white-space:nowrap;
                a{
                  color:#343434;
                  text-decoration:underline;
                  &:hover{
                    text-decoration:none!important;
                    color:#38aed5;
                  }
                }
                &+li{
                  margin-top:11px;
                }
                &:before{
                  content:"";
                  display:block;
                  width:20px;
                  height:20px;
                  position:absolute;
                  top:0.2em;
                  left:0;
                  background-image:url(../img/check_icon.png);
                  background-size:20px auto;
                  background-repeat:no-repeat;
                  background-position:center center;
                }
              }
            }
          }
          &:after{
            content:"";
            display:block;
            width:146px;
            height:140px;
            background-position:top center;
            background-repeat:no-repeat;
            background-size:146px auto;
            background-image:url(../img/beginner/illust1.png);
            position:absolute;
            bottom:-10px;
            right: -120px;
          }
        }
        .linkBox{
          float:right;
          text-align:right;
          width:400px;
          margin-top:27px;
          li{
            &+li{
              margin-top:30px;
            }
          }
        }
      }
      #ContBox03{
        text-align:center;
        ul{
          position:relative;
          z-index:0;
          margin-top:64px;
          li{
            width:310px;
            height:490px;
            float:left;
            background-color:#fff;
            border-radius:10px;
            position:relative;
            padding:45px 19px 25px;
            &+li{
              margin-left:25px;
            }
            &:before{
              content:"";
              display:block;
              width:128px;
              height:97px;
              border-radius:50%;
              background-color:#fff;
              position:absolute;
              top:-28px;
              left:0;
              right: 0;
              margin:auto;
              //z-index:-1;
              background-image:url(../img/top/bg_img.png);
              background-size:90px auto;
              background-repeat:no-repeat;
              background-position:top 23px center;
            }
            dl{
              position:relative;
              dt{
                font-size:19px;
                line-height:30px;
                font-weight:700;
                color:#38aed5;
                text-align:center;
                height:90px;
                display:table;
                width:100%;
                margin-bottom:30px;
                letter-spacing:0.02em;
                span{
                  display:table-cell;
                  vertical-align:middle;
                  width:100%;
                }
              }
              dd{
                margin-top:18px;
                font-size:16px;
                line-height:24px;
                text-align:justify;
                img{
                  width:100%;
                }
              }
            }
          }
        }
        .roundLinkBtn{
          margin-top:74px;
          margin-bottom:63px;
        }
      }
      #ContBox04{
        .owl-carousel{
          padding-top:95px;
        }
        .owl-item{
          width:310px!important;
        }
        .owl-nav{
          position:absolute;
          top:11px;
          left:50%;
          transform: translateX(-50%);
          .owl-next,.owl-prev{
            width:48px;
            height:48px;
            border-radius:50%;
            background-color:#f7f7f7;
            background-repeat: no-repeat;
            background-position: center center;
            background-image: url(../img/arrow_next.png);
            background-size: 8px auto;
            margin:0 25px;
            span{
              display:none;
            }
            &:hover{
              background-color:#38aed5;
              background-image: url(../img/arrow_next_on.png);
            }
          }
          .owl-prev{
            background-image: url(../img/arrow_prev.png);
            &:hover{
              background-image: url(../img/arrow_prev_on.png);
            }
          }
        }
        .popup-image{
          position:relative;
          a{
            position:absolute;
            width:65px;
            height:65px;
            text-indent:-9999px;
            display:block;
            background-repeat: no-repeat;
            background-position: center center;
            background-image: url(../img/popup_icon.png);
            background-size: 65px auto;
            bottom:15px;
            right: 15px;
            &:hover{
              opacity:0.5;
            }
          }
        }
        .txt{
          width:100%;
          background:#f7f7f7;
          border-radius:10px;
          padding:15px 25px;
          margin-top:10px;
          font-size:16px;
          font-weight:700;
          line-height:26px;
          a{
            color:#343434;
          }
        }
        .linkBtn{
          margin-top:18px;
        }
      }
      #ContBox05{
        .voiceBox{
          &:first-of-type{
            margin-top:48px;
          }
          p{
            font-size:16px;
            line-height:26px;
            text-align:justify;
          }
        }
        .linkBtn{
          margin-top:40px;
        }
      }
      #ContBox06{
        .entryBlogWrap{
          dl{
            margin-top:39px;
            &:nth-of-type(n+4){
              margin-top:60px;
            }
          }
        }
        .linkBtn{
          margin-top:40px;
        }
        #SubBlogBox{
          padding:8px 0 0;
          ul{
            li{
              margin-left:0;
              margin-right:40px;
            }
          }
        }
      }
      #ContBox07{
        padding-bottom:83px;
        .gMap{
          width:100%;
          height:400px;
          padding:10px;
          background:#fff;
          margin-top:37px;
          iframe{
            width:100%;
          }
        }
        .linkTxt{
          text-align:right;
          margin-top:20px;
          a{
            color:#24386b;
            letter-spacing:.05em;
            font-size:16px;
            font-weight:700;
            &:after{
              content: "";
              display: inline-block;
              margin-left:10px;
              width: 13px;
              height: 13px;
              background-repeat: no-repeat;
              background-position: center center;
              background-image: url(../img/about/maplink.png);
              background-size: 13px auto;
              vertical-align: -1px;
            }
            &:hover{
              &:after{
                background-image: url(../img/about/maplink_on.png);
              }
            }
          }
        }
        .roundLinkBtn{
          margin-top:25px;
        }
      }
    }
    .mfp-close{
      position: absolute;
      top: auto;
      bottom:60px;
      right: 24px;
      padding: 0;
      width: 64px;
      height: 64px;
      background: url(../img/popup_icon_minus.png) no-repeat center center;
      background-size: 64px auto;
      opacity: 1;
      font-size: 0;
      &:hover{
        opacity:0.7;
      }
    }
  }

  /*
  パーソナルラボRについて
  -------------------------------------*/
  #PageAbout.pageIndex {
    #Main{
      #ContBox01{
        padding:70px 0 72px;
        .catch_title{
          margin-bottom:55px;
        }
        .topImgBox{
          .txt{
            float:left;
            width:412px;
            p{
              letter-spacing:.05em;
              margin-bottom:12px;
            }
          }
          .img{
            float:right;
            width:520px;
            margin-top:7px;
          }
        }
        .linkBox{
          text-align:center;
          margin-top: 55px;
          li{
            margin: 49px 30px 50px 30px;
            &+li{
              margin-left:46px;
            }
          }
        }
      }
      #ContBox02{
        padding-bottom:92px;
        .border_title{
          margin-bottom:26px;
        }
        p{
          letter-spacing:.05em;
          margin-bottom:66px;
        }
        .imgList01{
          li{
            float:left;
            width:465px;
            &:nth-child(2n){
              margin-left:50px;
            }
            &:nth-child(1),&:nth-child(2){
              margin-bottom:46px;
            }
            dl{
              dt{
                color:#38aed5;
                font-weight:700;
                text-align:center;
                font-size:19px;
                letter-spacing:.05em;
                padding:20px 0 10px;
              }
              dd{
                &:nth-of-type(2){
                  padding:0 36px;
                  letter-spacing:.04em;
                }
              }
            }
          }
        }
        .imgList02{
          margin-top:67px;
          li{
            width:210px;
            float:left;
            margin-right:calc(140px / 3);
            &:nth-child(4n){
              margin-right:0;
            }
            dl{
              dt{
                height:68px;
                text-align:center;
                color:#38aed5;
                font-size:18px;
                font-weight:700;
                padding-top:12px;
              }
            }
          }
        }
      }
      #ContBox03{
        padding-bottom:50px;
        .border_title{
          margin-bottom:42px;
        }
        .mapBox{
          .gMap{
            border:10px solid #fff;
            height:400px;
            iframe{
              width:100%;
              height:100%;
            }
          }
          .bigmap{
            font-size:16px;
            font-weight:700;
            text-align:right;
            margin-top:15px;
            margin-right: 5px;
            a{
              color:#24386b;
              letter-spacing:.05em;
              &:after{
                content: "";
                display: inline-block;
                margin-left:10px;
                width: 13px;
                height: 13px;
                background-repeat: no-repeat;
                background-position: center center;
                background-image: url(../img/about/maplink.png);
                background-size: 13px auto;
                vertical-align: -1px;
              }
            }
          }
          .access{
            background:#fff;
            width:485px;
            padding:16px 0 16px 30px;
            letter-spacing:.05em;
            margin-top:-24px;
            margin-bottom:75px;
            dt{
              font-size:17px;
              color:#38aed5;
              font-weight:700;
              letter-spacing: .01em;
              &:before{
                content: "";
                display: inline-block;
                margin-right: 16px;
                width: 18px;
                height: 20px;
                background-repeat: no-repeat;
                background-position: center center;
                background-image: url(../img/about/icon_kokyo.png);
                background-size: 18px auto;
                vertical-align: -4px;
              }
            }
            dd{
              margin-top:12px;
              letter-spacing: .14em;
              &:nth-of-type(2){
                margin-top:6px;
              }
              span{
                position:relative;
                margin-left:64px;
                &:before{
                  position:absolute;
                  margin-left:-57px;
                  top:10px;
                  content: "";
                  display: inline-block;
                  width: 45px;
                  height: 1px;
                  background: #343434;
                }
                a{
                  color:#24386b;
                  font-weight:700;
                  &:after{
                    content: "";
                    display: inline-block;
                    margin-left: 6px;
                    width: 13px;
                    height: 13px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-image: url(../img/about/maplink.png);
                    background-size: 13px auto;
                    vertical-align: -1px;
                  }
                }
              }
              &:nth-of-type(2){
                span{
                  margin-left:47px;
                  &:before{
                    margin-left:-42px;
                    width: 30px;
                  }
                }
              }
            }
            &.car{
              margin-top: 14px;
              dt{
                &:before{
                  background-image: url(../img/about/icon_car.png);
                  background-size: 22px auto;
                  width:22px;
                }
              }
              dd{
                margin-top:9px;
                &:nth-of-type(2){
                  letter-spacing:.05em;
                }
                span{
                  margin-left:78px;
                  letter-spacing:.14em;
                  &:before{
                    margin-left:-74px;
                    width: 63px;
                  }
                }
                &:nth-of-type(2){
                  span{
                    margin-left:50px;
                    &:before{
                      margin-left:-41px;
                      width: 30px;
                    }
                  }
                }
              }
            }
          }
          &.mapBox02{
            .left{
              float:left;
              width:485px;
              .gMap{
                width:485px;
                height:310px;
              }
              .bigmap{
                margin-top:9px;
                margin-right: 1px;
              }
            }
            .right{
              float:right;
              width:435px;
              margin-top:-95px;
              .table{
                width:100%;
                tr{
                  th{
                    width:42%;
                    border-bottom:1px solid #343434;
                    text-align:center;
                    vertical-align:middle;
                    padding:24px;
                    padding-right:33px;
                    position:relative;
                    &:after{
                      content:"";
                      display:block;
                      width:8px;
                      height:calc(100% + 4px);
                      background:#f0f8fb;
                      position:absolute;
                      top:-1px;
                      right: 0;
                    }
                    &:nth-of-type(2){
                      border-top:1px solid #343434;
                    }
                  }
                  td{
                    margin-left:8px;
                    width:58%;
                    border-bottom:1px dashed #343434;
                    padding:23px;
                    padding-left:16px;
                    vertical-align:middle;
                  }
                  &:first-child{
                    th{
                      //text-indent:-9999px;
                      height:198px;
                      /*background-repeat: no-repeat;
                      background-position: top 33px left 50px;
                      background-image: url(../img/about/rlogo.png);
                      background-size:73px auto;*/
                      img {
                        width: 75px;
                      }
                    }
                    td{
                      text-align:center;
                      padding:0;
                      img{
                        width:128px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  トレーナー紹介
  -------------------------------------*/
  #PageTrainer.pageIndex {
    #Main{
      #ContBox01{
        padding:70px 0 84px;
        .catch_title{
          margin-bottom:56px;
        }
        .topImgBox{
          .txt{
            width:472px;
            float:left;
            p{
              letter-spacing:.1em;
              margin-bottom: 12px;
            }
          }
          .img{
            float:right;
            width:460px;
            margin-top: 8px;
            .trainerImg{
              width:100%;
              position:relative;
              img{
                width:100%;
              }
              dl{
                width:149px;
                height:112px;
                position:absolute;
                top:208px;
                right:0;
                background:#fff;
                text-align:center;
                padding-top:26px;
                dt{
                  font-size:12px;
                  letter-spacing: .05em;
                }
                dd{
                  font-size:15px;
                  font-weight:700;
                  margin-top: 3px;
                  letter-spacing: .01em;
                  span{
                    display:block;
                    font-size:11px;
                  }
                }
              }
            }
            .contSubBox{
              margin-top:38px;
              &:last-child{
                margin-top:30px;
              }
              h4{
                position:relative;
                margin-left:91px;
                font-weight:700;
                &:before{
                  content: "";
                  position:absolute;
                  margin-right: 17px;
                  width: 20px;
                  height: 3px;
                  top:7px;
                  background: #343434;
                  margin-left:-35px;
                }
              }
              ul{
                margin-left:91px;
                margin-top:5px;
                letter-spacing: 0.01em;
                line-height:20px;
              }
              p{
                margin-left:91px;
                margin-top:5px;
                font-size:14px;
                line-height:20px;
                letter-spacing: .05em;
              }
            }
          }
        }
        .linkBox{
          text-align:center;
          margin-top: 21px;
          li{
            margin:49px 43px 50px 11px;
            &+li{
              margin-left:33px;
            }
          }
        }
      }
      #ContBox02{
        padding-bottom:152px;
        .contSubBox{
          width:760px;
          margin:0 auto;
          h4{
            font-size:30px;
            line-height:48px;
            color:#38aed5;
            text-align:center;
            margin-top:57px;
            margin-bottom:41px;
            letter-spacing:.01em;
          }
          p{
            margin-bottom: 12px;
          }
          &+.contSubBox{
            margin-top:82px;
          }
          .imgTxt{
            .left{
              width:445px;
              float:left;
              img{
                width:100%;
                margin-top: 6px;
              }
            }
            .right{
              width:270px;
              float:right;
              img{
                width:100%;
                margin-top: 6px;
              }
            }
            &+.imgTxt{
              margin-top:15px;
            }
          }
          img {
            &.alignright {
              float: right;
              margin: 0.5em 0 0.5em 1em;
            }
            &.alignleft {
              float: left;
              margin: 0.5em 1em 0.5em 0;
            }
            &.aligncenter {
              display: block;
              margin-left: auto;
              margin-right: auto;
            }
          }
        }
      }
    }
  }

  /*
  はじめての方へ
  -------------------------------------*/
  #PageBeginner.pageIndex {
    #Main{
      #ContBox01{
        padding-top:70px;
        padding-bottom:110px;
        .catch_title{
          margin-bottom:28px;
        }
        p{
          text-align:center;
        }
        ul{
          margin-top:45px;
          li{
            dl{
              padding-top:40px;
              dt{
                font-size:20px;
                font-weight:700;
                margin-top:225px;
                span{
                  display:block;
                  font-size:16px;
                }
              }
              dd{
              }
            }
            width:310px;
            height:380px;
            background-color:#fff;
            border-radius:10px;
            float:left;
            color:#38aed5;
            text-align:center;
            background-repeat: no-repeat;
            background-position: top 104px center;
            background-image: url(../img/beginner/illust1.png);
            background-size:160px auto;
            &:nth-child(1){
              dl{
                dt{
                  margin-top:213px;
                }
              }
            }
            &:nth-child(2){
              background-image: url(../img/beginner/illust2.png);
              background-position: top 111px center;
              background-size:215px auto;
            }
            &:nth-child(3){
              background-image: url(../img/beginner/illust3.png);
              background-position: top 111px center;
              background-size:200px auto;
            }
            &+li{
              margin-left:25px;
            }
          }
        }
      }
      #ContBox02{
        padding-bottom:0;
        .imgTxtBox{
          padding:40px 0 40px;
          &:first-child{
            padding-top:26px;
          }
          &+.imgTxtBox{
            border-top:1px dashed #38aed5;
          }
          .img{
            width:360px;
            float:left;
          }
          .txt{
            width:575px;
            float:right;
            margin-top:-5px;
            h4{
              color:#38aed5;
              font-size:22px;
              font-weight:700;
              span{
                margin-right:25px;
                img{
                  width:83px;
                }
              }
            }
            p{
              margin-top: 17px;
              letter-spacing: .05em;
            }
          }
        }
        .center{
          margin-top:33px;
          p{
            text-align:center;
            font-size:18px;
            letter-spacing:.1em;
            margin-bottom:6px;
          }
        }
      }
      #ContBox03{
        padding-bottom:58px;
        .imgList{
          margin-top:43px;
          li{
            width:300px;
            float:left;
            margin-right:40px;
            &:nth-child(3n){
              margin-right:0;
            }
            dl{
              dt{
                height:98px;
                text-align:center;
                color:#38aed5;
                font-size:18px;
                font-weight:700;
                padding-top:15px;
              }
            }
          }
        }
      }
    }
  }

  /*
  共通要素
  -------------------------------------*/
  #PagePersonalTraining,
  #PagePain,
  #PageDiet,
  #PageCouese{
    #Main{
      #ContBox01{
        padding:73px 0 80px;
        .catch_title{
          letter-spacing:0.09em;
          margin-bottom:55px;
        }
      }
      .topImgBox{
        .txt{
          width:415px;
          float:left;
          p{
            letter-spacing:.1em;
            margin-bottom: 12px;
          }
        }
        .img{
          float:right;
          width:520px;
          margin-top: 8px;
          img{
            max-width:100%;
          }
        }
      }
      .recommend{
        border-radius:10px;
        background:#fff;
        padding:21px 50px 34px;
        position:relative;
        z-index:0;
        margin-top:19px;
        dt{
          margin:0 auto 22px;
          border-bottom:1px solid #f2f2f2;
          font-size:19px;
          font-weight:700;
          color:#38aed5;
          text-align:center;
          position:relative;
          background:#fff;
          padding-bottom:6px;
          &:before{
            content:"";
            //display:block;
            //width:85%;
            //height:15px;
            position:absolute;
            top:50%;
            bottom:0;
            left:20px;
            right: 20px;
            margin:auto;
            border-radius:100px / 10px;
            box-shadow:0 5px 15px -5px rgba(0, 0, 0, 0.3);
            //background: 0 15px 10px rgba(0, 0, 0, 0.7);
            z-index:-1;
          }
        }
        dd{
          font-size:16px;
          font-weight:700;
          ul{
            margin-left:10px;
            li{
              padding-left:10px;
              position:relative;
              &+li{
                margin-top:6px;
              }
              &:before{
                content:"";
                display:block;
                width:4px;
                height:4px;
                border-radius:50px;
                position:absolute;
                background:#343434;
                top:0.6em;
                left:0;
              }
            }
          }
        }
      }
      .linkBox{
        text-align:center;
        margin-top: 29px;
        li{
          &+li{
            margin-left:40px;
          }
        }
      }
      .imgTxtBox{
        padding:40px 0;
        &:after{
          content: "";
          display: block;
          clear: both;
        }
        &:first-child{
          padding-top:29px;
        }
        &+.imgTxtBox{
          border-top:1px dashed #38aed5;
        }
        .img{
          width:360px;
          float:left;
        }
        .txt{
          width:580px;
          float:right;
          h4{
            color:#38aed5;
            font-size:22px;
            font-weight:700;
            span{
              vertical-align:middle;
              margin-right:20px;
            }
          }
          p{
            margin-top: 10px;
            letter-spacing: .05em;
          }
        }
      }
      .priceBox{
        margin-top:47px;
        .priceInner{
          width:800px;
          margin:0 auto;
          background-color:#fff;
          padding:30px 68px 45px;
          margin-bottom:60px;
          .priceDetaile{
            font-weight:700;
            font-size:20px;
            text-align:center;
            margin-bottom:25px;
            span{
              font-size:12px;
              &.price{
                font-size:30px;
              }
            }
            ul{
              li{
                display:inline-block;
                &+li{
                  &:before{
                    content:"\02f";
                    margin-right:0.5em;
                    font-size:22px;
                  }
                }
              }
            }
          }
          .note{
            text-align:center;
            margin-top:9px;
            li{
              font-size:16px;
              display:inline-block;
            }
          }
          .table{
            width:100%;
            tr{
              th,td{
                vertical-align:middle;
                font-size:16px;
                line-height:24px;
              }
              th{
                width:178px;
                text-align:center;
                border-top:1px solid #343434;
                padding-right:8px;
                position:relative;
                font-weight:700;
                &:after{
                  content:"";
                  display:block;
                  width:8px;
                  height:calc(100% + 2px);
                  background:#fff;
                  position:absolute;
                  top:-1px;
                  right: 0;
                }
              }
              td{
                padding:25px 0 20px 19px;
                border-top:1px dashed #343434;
                ul{
                  margin-left:10px;
                  margin-bottom:7px;
                  li{
                    position:relative;
                    padding-left:12px;
                    &:before{
                      content:"";
                      display:block;
                      width:4px;
                      height:4px;
                      background:#343434;
                      border-radius:50px;
                      position:absolute;
                      top:0.7em;
                      left:0;
                    }
                    &+li{
                      margin-top:10px;
                    }
                  }
                }
              }
              &:last-of-type{
                th{
                  border-bottom:1px solid #343434;
                }
                td{
                  border-bottom:1px dashed #343434;
                }
              }
            }
          }
        }
        p{
          text-align:center;
          font-size:18px;
          line-height:1;
          margin-bottom:10px;
        }
      }
      .entryBlogWrap{
        dl{
          margin-top:22px;
          &:nth-of-type(n+4){
            margin-top:62px;
          }
        }
      }
      .blogLink{
        text-align:center;
        margin-top:50px;
      }
    }
  }
  #PagePain.pageIndex,
  #PageDiet.pageIndex{
    #MainImg{
      #MainImgInner{
        background-image:url(../img/header/pagettl3.jpg);
        h2{
          padding-top: 79px;
          span{
            display:block;
            font-size:18px;
            letter-spacing: .05em;
            &:after{
              content: "";
              display: block;
              margin:19px auto;
              width: 70px;
              height: 1px;
              background: #fff;
            }
          }
        }
      }
    }
  }

  /*
  パーソナルトレーニングについて
  -------------------------------------*/
  #PagePersonalTraining.pageIndex {
    #Main{
      #ContBox02{
        padding-bottom:0;
      }
      #ContBox03{
        padding:74px 0 126px;
        .openBox{
          background-color:#f7f7f7;
          width:800px;
          margin:0 auto;
          padding:55px 38px 47px 45px;
          dl{
            dt{
              font-size:20px;
              font-weight:700;
              position:relative;
              line-height:40px;
              padding-left:37px;
              padding-right:54px;
              background-image:url(../img/check_icon.png);
              background-repeat:no-repeat;
              background-position:center left;
              background-size:22px auto;
              span{
                position:absolute;
                top:0;
                right: 0;
                text-indent:-9999px;
                display:block;
                width:40px;
                height:40px;
                background-color:#d5ebf3;
                border-radius:50px;
                background-image:url(../img/plus.png);
                background-repeat:no-repeat;
                background-position:center center;
                background-size:20px auto;
                cursor:pointer;
                &:hover{
                  background-color:#38aed5;
                  background-image:url(../img/plus_w.png);
                }
              }
              &.active{
                span{
                  background-image:url(../img/minus.png);
                  &:hover{
                    background-image:url(../img/minus_w.png);
                  }
                }
              }
            }
            dd{
              display:none;
              margin-top:24px;
              padding-left:40px;
              padding-right:54px;
              padding-bottom:7px;
              p{
                font-size:16px;
                line-height:30px;
                letter-spacing:0.08em;
              }
              ul{
                margin-top:27px;
                li{
                  display:inline-block;
                  line-height:42px;
                  font-size:17px;
                  font-weight:700;
                  color:#38aed5;
                  margin-right:6px;
                  a{
                    padding:0 18px;
                    background-color:#fff;
                    border-radius:21px;
                    display:block;
                    &:hover{
                      background:#d5ebf3;
                      text-decoration:none;
                    }
                  }
                }
              }
            }
            &+dl{
              margin-top:42px;
            }
          }
        }
      }
    }
  }

  /*
  整体（痛みの改善）
  -------------------------------------*/
  #PagePain.pageIndex {
    #Main{
      #ContBox01{
        padding:73px 0 120px;
        .catch_title{
          letter-spacing:0.03em;
        }
        .topImgBox{
          .txt{
            width:495px;
            p{
              letter-spacing:.1em;
              margin-bottom: 12px;
            }
          }
          .img{
            width:440px;
          }
        }
      }
      #ContBox02{
        padding:114px 0 85px;
      }
      #ContBox03{
        .priceBox{
          .priceInner{
            padding:30px 68px 30px;
            .priceDetaile{
              margin-bottom:0;
            }
          }
        }
      }
    }
  }

  /*
  集中ダイエット
  -------------------------------------*/
  #PageDiet.pageIndex {
    #Main{
      #ContBox01{
        padding:73px 0 120px;
        .catch_title{
          letter-spacing:0.1em;
        }
        .topImgBox{
          .txt{
            width:495px;
            p{
              letter-spacing:.1em;
              margin-bottom: 12px;
            }
          }
          .img{
            width:440px;
          }
        }
      }
      #ContBox02{
        padding:114px 0 85px;
      }
      #ContBox03{
        .priceBox{
          .priceInner{
            padding:37px 68px 47px;
            .priceDetaile{
              margin-bottom:0;
            }
            .table{
              margin-top:33px;
            }
          }
        }
      }
    }
  }

  /*
  料金・コース
  -------------------------------------*/
  #PageCouese.pageIndex {
    #Main{
      .contBox{
        p{
          &:first-child{
            font-size:16px;
            line-height:30px;
            text-align:center;
          }
        }
        .imgBox{
          margin-top:38px;
          display:table;
          width:100%;
          .left,.right{
            display:table-cell;
            vertical-align:top;
          }
          .left{
            width:530px;
            background: #f7f7f7;
            border-radius:10px 0 0 10px;
            text-align:right;
            padding-right:27px;
            padding-bottom:24px;
            .recommend{
              background: #f7f7f7;
              border-radius:0;
              padding:12px 20px 0 24px;
              dt{
                background: #f7f7f7;
                font-size:22px;
                text-align:center;
                width:354px;
                margin:0 auto 33px;
              }
              dd{
                text-align:left;
                ul{
                  margin-left:33px;
                  li{
                    &+li{
                      margin-top:7px;
                    }
                  }
                }
              }
            }
            .roundLinkBtn{
              margin:19px 20px 19px 24px;
              &:after{
                margin-left: 16px;
                width: 7px;
                height: 12px;
                background-size: 7px auto;
              }
              a{
                font-size:16px;
                &:before{
                  top: -10px;
                  right: -10px;
                  width: 78px;
                  height: 78px;
                }
              }
            }
          }
          .right{
            width:450px;
            overflow:hidden;
            border-radius:0 10px 10px 0;
            position:relative;
            img{
              width:auto;
              height:100%;
              max-width:none;
              position:absolute;
              top:0;
              left:50%;
              right: 0;
              transform:translateX(-50%);
            }
          }
        }
        .priceBox{
          margin-top:40px;
          padding:39px 90px 75px;
          .priceTit{
            font-size:26px;
            font-weight:700;
            text-align:center;
            margin-bottom:30px;
          }
        }
      }
      #ContBox01{
        padding:69px 0 84px;
        .catch_title{
          letter-spacing:0.05em;
          margin-bottom:27px;
        }
        ul{
          text-align:center;
          margin-top:40px;
          li{
            display:inline-block;
            vertical-align:bottom;
            &+li{
              margin-left:44px;
            }
            a{
              display:inline-block;
              min-width:112px;
              color:#24386b;
              font-size:17px;
              font-weight:700;
              padding-bottom:30px;
              background-position:bottom 4px center;
              background-repeat:no-repeat;
              background-size:16px auto;
              background-image:url(../img/arrow_b.png);
              transition:0.5s;
              &:before{
                content:"";
                display:block;
                width:100%;
                height:134px;
                background-position:top center;
                background-repeat:no-repeat;
                background-size:112px auto;
                background-image:url(../img/beginner/illust1.png);
              }
              &:hover{
                background-position:bottom center;
                background-image:url(../img/arrow_b_on.png);
                text-decoration:none!important;
                &:before{
                  opacity:0.7;
                }
              }
            }
            &:nth-child(2){
              a{
                min-width:159px;
                &:before{
                  height:138px;
                  background-size:159px auto;
                  background-image:url(../img/beginner/illust2.png);
                }
              }
            }
            &:nth-child(3){
              a{
                min-width:140px;
                &:before{
                  height:125px;
                  background-size:140px auto;
                  background-image:url(../img/illust01.png);
                }
              }
            }
            &:nth-child(4){
              margin-left:53px;
              a{
                min-width:137px;
                &:before{
                  height:126px;
                  background-size:137px auto;
                  background-image:url(../img/illust02.png);
                }
              }
            }
            &:nth-child(5){
              margin-left:60px;
              a{
                min-width:139px;
                &:before{
                  height:142px;
                  background-size:139px auto;
                  background-image:url(../img/illust03.png);
                }
              }
            }
          }
        }
      }
      #ContBox02{
        .imgBox{
          .left,.right{
            height:330px;
          }
          .left{
            width:590px;
            background: transparent;
            overflow:hidden;
            border-radius:10px 0 0 10px;
            position:relative;
            img{
              width:auto;
              height:100%;
              max-width:none;
              position:absolute;
              top:0;
              left:50%;
              right: 0;
              transform:translateX(-50%);
            }
          }
          .right{
            width:390px;
            img{
              left:auto;
              right: 0;
              transform:translateX(0);
            }
          }
        }
        .priceBox{
          .priceInner{
            padding:32px 68px 6px;
          }
        }
      }
      #ContBox03{
        padding:11px 0 122px;
        .imgBox{
          .right{
            height:370px;
          }
        }
      }
      #ContBox04{
        padding:13px 0 122px;
        .priceBox{
          .priceInner{
            padding:33px 68px 5px;
          }
        }
      }
      #ContBox05{
        padding:12px 0 122px;
        .priceBox{
          .priceInner{
            padding:39px 68px 45px;
            .priceDetaile{
              margin-bottom:9px;
            }
            .table{
              margin-top:30px;
            }
          }
        }
      }
      #ContBox06{
        padding:16px 0 122px;
        .imgBox{
          .left,.right{
            height:330px;
          }
          .right{
            width:450px;
            overflow:hidden;
            border-radius:0 10px 10px 0;
            position:relative;
            img{
              width:auto;
              height:100%;
              max-width:none;
              position:absolute;
              top:0;
              left:50%;
              right: 0;
              transform:translateX(-50%);
            }
          }
        }
        .priceBox{
          .priceInner{
            padding:33px 68px 45px;
            .priceDetaile{
              margin-bottom:9px;
            }
            .table{
              margin-top:30px;
            }
          }
        }
      }
    }
  }


  /*
  お客様の声共通
  -------------------------------------*/
  #Main{
    #SubVoiceBox{
      text-align:left;
      margin: 55px 0 28px;
      .roundLinkBtn{
        margin-left:0;
        margin-right:21px;
      }
      dl{
        display:inline-block;
        vertical-align:middle;
        dt{
          display:inline-block;
          vertical-align:middle;
          margin-left:36px;
          position:relative;
          &:after{
            content: "";
            position: absolute;
            bottom: 3px;
            right: -23px;
            width: 13px;
            height: 6px;
            background: #38aed5;
          }
        }
        dd{
          display:inline-block;
          vertical-align:middle;
          margin-left:62px;
          margin-top: 15px;
          ul{
            width:570px;
            li{
              display:inline-block;
              &+li{
                margin-left:50px;
              }
              &:nth-of-type(3){
                display:block;
              }
              &:nth-of-type(3),&:nth-of-type(4){
                margin-left:0;
              }
              a{
                font-size:16px;
                line-height:36px;
                color:#24386b;
                font-weight:700;
                letter-spacing: .05em;
                &:after{
                  content: "";
                  position: relative;
                  display: inline-block;
                  margin-left: 12px;
                  width: 9px;
                  height: 16px;
                  background-repeat: no-repeat;
                  background-position: center center;
                  background-image: url(../img/arrow_round.png);
                  background-size: 9px auto;
                }
              }
            }
          }
        }
      }
    }
    .entryVoiceWrap{
      text-align:left;
      letter-spacing:-.4em;
      dl{
        width:300px;
        display:inline-block;
        margin-right:40px;
        margin-top:58px;
        letter-spacing:.05em;
        &:nth-of-type(3n){
          margin-right:0;
        }
        dt{
          text-align:center;
          font-weight:700;
          margin-top:12px;
          letter-spacing:.05em;
          a{
            color:#343434;
          }
        }
        dd{
          border:1px solid #e9e9e9;
          a{
            display:block;
            position:relative;
            &:hover:after{
              content:"詳しく見る";
              font-weight:700;
              text-align:center;
              color:#fff !important;
              line-height:430px;
              position:absolute;
              top:0;
              left:0;
              background-color:rgba(#000,0.5);
              width:100%;
              height:100%;
            }
            &:hover{
            }
          }
        }
      }
    }
    .moreBtn{
      margin-top:91px;
      margin-left: -14px;
      a{
        span{
          &:after{
            background-image: url(../img/voice/icon_plus.png);
            background-size: 20px auto;
            width:20px;
            margin-left: 37px;
          }
        }
        &:before{
          content: "";
          position: absolute;
          top: -6px;
          right: -47px;
          bottom: 0;
          border-radius: 100%;
          width: 60px;
          height: 60px;
          background: #d5ebf3;
        }
      }
    }
  }

  /*
  お客様の声
  -------------------------------------*/
  #PageVoice.pageIndex {
    #Main{
      #ContBox01{
        padding:70px 0 122px;
        .catch_title{
          margin-bottom:57px;
        }
      }
      #ContBox02{
        text-align:center;
        .entryVoiceWrap{
          dl {
            dd {
              img {
                height: auto;
              }
            }
          }
        }
      }
    }
  }

  /*
  お客様の声（カテゴリアーカイブ）
  -------------------------------------*/
  #PageVoice.pageArchive {
    #Main{
      #SubVoiceBox{
        margin-top:0;
        padding:45px 0 60px;
      }
      .contBox{
        text-align:center;
        padding:85px 0 92px;
        .border_title{
          margin-bottom: 10px;
        }
        .moreBtn{
          margin-top:93px;
        }
        .entryVoiceWrap{
          dl {
            dd {
              img {
                height: auto;
              }
            }
          }
        }
      }
    }
  }

  /*
  お客様の声（記事詳細）
  -------------------------------------*/
  #PageVoice.pageEntry {
    #Main{
      #SubVoiceBox{
        margin-top:0;
        padding:45px 0 60px;
      }
      .contBox{
        padding:86px 0 135px;
        .infoBox{
          text-align:center;
          .date{
            font-size:18px;
            text-align:center;
            margin-bottom:6px;
          }
          .entryTitle{
            font-size:30px;
          }
          .details{
            font-weight:700;
            margin-top:8px;
          }
          .category{
            margin-top:18px;
            letter-spacing: -.4em;
            li{
              display:inline-block;
              a{
                display:block;
                padding:12px 16px;
                line-height:1;
                font-size:14px;
                font-weight:700;
                border-radius:100vh;
                background:#f1f1f1;
                letter-spacing: .05em;
                &:hover{
                  text-decoration:none;
                  background:#d5ebf3;
                  border-radius:100vh;
                }
              }
              &+li{
                margin-left:8px;
              }
            }
          }
        }
        .entryImg{
          margin:56px 0;
          text-align:center;
          img{
            border: 1px solid #e9e9e9;
          }
        }
        .pageMove{
          text-align:center;
          margin-top:76px;
          ul{
            margin:0 auto;
            position:relative;
            width:365px;
            li{
              display:inline-block;
              a{
                color:#24386b;
                font-size:16px;
                font-weight:700;
                letter-spacing:.05em;
              }
              &.prev{
                position:absolute;
                left:0;
                a{
                  &:before{
                    content: "";
                    display: inline-block;
                    margin-right: 17px;
                    width: 8px;
                    height: 16px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-image: url(../img/voice/arrow_before.png);
                    background-size: 8px auto;
                    vertical-align: middle;
                  }
                }
              }
              &.back{
                a{
                  &:after{
                    content: "";
                    display: inline-block;
                    margin-left: 5px;
                    width: 18px;
                    height: 16px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-image: url(../img/voice/arrow_back.png);
                    background-size: 18px auto;
                    vertical-align: middle;
                  }
                }
              }
              &.next{
                position:absolute;
                right:0;
                a{
                  &:after{
                    content: "";
                    display: inline-block;
                    margin-left: 17px;
                    width: 8px;
                    height: 16px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-image: url(../img/voice/arrow_next.png);
                    background-size: 8px auto;
                    vertical-align: middle;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  公式ブログ
  -------------------------------------*/
  #PageBlog.pageIndex {
    #Main{
      .contBox{
        padding:60px 0 82px;
        text-align:center;
        dl {
          dd.img {
            img {
              height: auto;
            }
          }
        }
      }
    }
  }

  /*
  公式ブログ（カテゴリアーカイブ）
  -------------------------------------*/
  #PageBlog.pageArchive {
    #Main{
      .contBox{
        padding:113px 0 90px;
        text-align:center;
        .border_title{
          margin-bottom:-2px;
        }
        .entryBlogWrap{
        }
        dl {
          dd.img {
            img {
              height: auto;
            }
          }
        }
      }
    }
  }

  /*
  公式ブログ（記事詳細）
  -------------------------------------*/
  #PageBlog.pageEntry {
    #Main{
      .contBox{
        padding:114px 0 79px;
        text-align:center;
        .infoBox{
          text-align:center;
          margin-bottom: 30px;
          .date{
            font-size:18px;
            text-align:center;
            margin-bottom:6px;
          }
          .entryTitle{
            font-size:30px;
          }
          .details{
            font-weight:700;
            margin-top:8px;
          }
          .category{
            margin-top:13px;
            letter-spacing: -.4em;
            li{
              display:inline-block;
              a{
                display:block;
                padding:12px 16px;
                line-height:1;
                font-size:14px;
                font-weight:700;
                border-radius:100vh;
                background:#f1f1f1;
                letter-spacing: .05em;
                &:hover{
                  text-decoration:none;
                  background:#d5ebf3;
                  border-radius:100vh;
                }
              }
              &+li{
                margin-left:8px;
              }
            }
          }
        }
        .entryImg{
          margin:56px 0;
          text-align:center;
          img{
            border: 1px solid #e9e9e9;
          }
        }
        .roundLinkBtn{
          margin: 44px 30px 50px 24px;
        }
      }
    }
  }

  /*
  form 共通
  -------------------------------------*/
  #PageContact,
  #PageReservation {
    #Main{
      #ContBox01{
        padding:73px 0 90px;
        .catch_title{
          margin-bottom:32px;
          letter-spacing:0.14em;
        }
        p{
          font-size:16px;
          line-height:30px;
          text-align:center;
        }
        .formTel{
          background:#fff;
          width:790px;
          height:138px;
          margin:35px auto 0;
          border-radius:10px;
          dl{
            display:table;
            width:100%;
            height:100%;
            dt,dd{
              display:table-cell;
              vertical-align:middle;
            }
            dt{
              width:405px;
              font-size:18px;
              color:#36466f;
              font-weight:700;
              padding-left:55px;
              position:relative;
              letter-spacing:0.02em;
              &:before{
                content:"";
                display:inline-block;
                margin-right:18px;
                width:24px;
                height:25px;
                vertical-align:middle;
                background-image:url(../img/footer/iconTel.png);
                background-repeat:no-repeat;
                background-position:center center;
                background-size:24px auto;
              }
              &:after{
                content:"";
                display:block;
                width:1px;
                height:70px;
                background:#24386b;
                position:absolute;
                top:0;
                right: 0;
                bottom:0;
                margin:auto;
              }
            }
            dd{
              color:#36466f;
              font-weight:700;
              line-height:1;
              text-align:center;
              a{
                font-size:39px;
                color:#36466f;
                letter-spacing:0.05em;
                text-decoration:none;
                &:hover{
                  opacity:1;
                  color:#36466f!important;
                  text-decoration:none;
                }
              }
              >span{
                font-size:18px;
                display:block;
                margin-top:12px;
                span{
                  font-size:14px;
                }
              }
            }
          }
        }
      }
      form{
        width:790px;
        margin:0 auto;
        dl{
          width:100%;
          display: table;
          padding:32px 0 0;
          dt,dd{
            display:table-cell;
            vertical-align:top;
          }
          dt{
            width:200px;
            font-size:17px;
            font-weight: 700;
            padding-left:9px;
            padding-top:10px;
          }
          dd{
            &:first-child{
              width:37px;
              text-align:center;
              padding-top:10px;
              span{
                display:inline-block;
                width:100%;
                height:21px;
                line-height:21px;
                border-radius:2px;
                font-size: 13px;
                color:#828282;
                text-align: center;
                background:#f6f6f6;
                &.required{
                  background:#ffdddd;
                  color:#ff5151;
                  border-radius:2px;
                }
              }
            }
            &:last-child{
              width:553px;
              font-size:16px;
              line-height:30px;
              input,textarea,select{
                background-color:#f5f5f5;
                width:100%;
                height:48px;
                border-radius:5px;
                padding-left:19px;
                letter-spacing:0.05em;
                &:focus{
                  border:1px solid #6ecae9;
                  background-color:#fafafa;
                  padding-left:18px;
                }
                &::placeholder {
                  color:#979797;
                }
              }
              textarea{
                height:240px;
                padding:14px;
                resize:none;
                &:focus{
                  padding-left:13px;
                  padding-top:13px;
                }
              }
              select,.select{
                width:264px;
                cursor: pointer;
                background-image: url(../img/select.png);
                background-repeat: no-repeat;
                background-position: center right 5px;
                background-size: 36px auto;
                padding-right:45px;
                option{
                  background-color:#fafafa;
                  border:none;
                }
              }
              select{
                color: #979797;
                option{
                  color:#343434;
                }
              }
              &.checkWrap{
                padding-top:8px;
                padding-left:4px;
              }
              .checker{
                width:17px;
                height:17px;
                display:inline-block;
                vertical-align:middle;
                span{
                  display: inline-block;
                  width: 17px;
                  height: 17px;
                  border:2px solid #b4b4b4;
                  border-radius:1px;
                  vertical-align: top;
                  &.checked{
                    &:after{
                      content:"";
                      display:block;
                      width:16px;
                      height:100%;
                      background: url(../img/checkbox.png) no-repeat center center;
                      background-size: 16px auto;
                      left:3px;
                      top:-2px;
                      position:absolute;
                    }
                  }
                  input{
                    width:17px;
                    height:17px;
                    position:absolute;
                    top:0;
                    left:0;
                    z-index:1;
                  }
                }
              }
              label{
                vertical-align:middle;
                font-weight:700;
                cursor:pointer;
                padding-left:8px;
                letter-spacing:0.05em;
                &:hover{
                  opacity:0.7;
                }
              }
              &.textarea{
                padding-top:8px;
              }
            }
          }
        }
        ul{
          position: relative;
          width:100%;
          margin:45px 0 0;
          li{
            &.reset{
              margin-top:25px;
              text-align:center;
              input[type="button"]{
                color: #24386b;
                font-size: 16px;
                text-decoration: none;
                background-image:url(../img/back.png);
                background-repeat:no-repeat;
                background-position:center right 1px;
                background-size:18px auto;
                padding-right:25px;
                font-weight:700;
                &:hover{
                  opacity:0.8;
                }
              }
            }
            #image-btn{
              display: block;
              width: 100%;
              height: 62px;
              margin: 0px auto;
              border-radius: 7.5px;
              background-color: #d5ebf3;
              opacity: 1;
              color: #38aed5;
              font-size: 20px;
              font-weight:700;
              text-decoration: none;
              line-height: 62px;
              &:hover{
                background-color:#38aed5;
                color:#fff;
              }
            }
          }
        }
      }
    }
  }
  #PageContact.pageConfirm,
  #PageContact.pageThanks,
  #PageReservation.pageConfirm,
  #PageReservation.pageThanks {
    #MainImg,#TopicPath{
      background:transparent;
    }
    #Main{
      .contBox{
        padding:69px 0 134px;
        .border_title{
          margin-bottom:56px;
        }
      }
      .contSubBox{
        h4{
          color:#38aed5;
          font-size:22px;
          font-weight:700;
          text-align:center;
          margin-bottom:10px;
        }
        p{
          font-size:16px;
          line-height:30px;
          text-align:center;
        }
      }
      form{
        margin-top:38px;
        dl{
          padding-top:15px;
          dd{
            &:last-of-type{
              padding-top:10px;
            }
          }
        }
      }
    }
  }


  /*
  お問い合わせ
  -------------------------------------*/
  #PageContact.pageIndex {
  }

  /*
  お問い合わせ（確認画面）
  -------------------------------------*/
  #PageContact.pageConfirm {
  }

  /*
  お問い合わせ（完了画面）
  -------------------------------------*/
  #PageContact.pageThanks {
  }

  /*
  体験コースお申し込み
  -------------------------------------*/
  #PageReservation.pageIndex {
    #Main {
      #ContBox01 {
        .formNotice {
          width: 790px;
          background-color: #fff;
          border-radius: 10px;
          padding: 51px;
          margin: 50px auto 0;
          h4 {
            font-size: 18px;
            font-weight: bold;
            color: #38aed5;
            line-height: 30px;
            margin-bottom: 30px;
          }
          p {
            text-align: justify;
            &:last-of-type { margin-bottom: 0; }
          }
        }
      }
      #ContBox02 {
        &.noShow { display: none; }
      }
    }
  }

  /*
  体験コースお申し込み（確認画面）
  -------------------------------------*/
  #PageReservation.pageConfirm {
  }

  /*
  体験コースお申し込み（完了画面）
  -------------------------------------*/
  #PageReservation.pageThanks {
  }

  /*
  プライバシーポリシー
  -------------------------------------*/
  #PagePolicy.pageIndex {
    #MainTxt{
      padding:20px 25px;
      #MainTxtInner{
        background:#f9f8f0;
        .innerBasic{
          background-image: url(../img/pagettl_r_pc.png);
          background-size: 121px auto;
          background-repeat: no-repeat;
          background-position: center right;
          max-width:1000px;
          width:auto;
          h2{
            text-align:right;
            line-height:140px;
            color:#38aed5;
            font-size:24px;
            font-weight: 700;
          }
        }
      }
    }
    #TopicPath{
      margin-top:0;
      background:transparent;
    }
    #Main{
      margin:53px 0 80px;
      p{
        font-size:18px;
        line-height:32px;
        text-align:center;
        margin-bottom:53px;
        letter-spacing:0.08em;
      }
      .contBox{
        padding:35px 0 30px;
        border-top:1px solid #d5d5d5;
        h3{
          font-size:20px;
          color:#343434;
          font-weight:700;
          margin-bottom:13px;
          letter-spacing:0.05em;
          &:before{
            content:"";
            display:inline-block;
            vertical-align:middle;
            margin-right: 17px;
            width:20px;
            height:5px;
            background:#343434;
          }
        }
        p,ul li,dl{
          text-align:left;
          font-size:16px;
          line-height:30px;
          margin-bottom:0;
          letter-spacing:0.06em;
          &+p,&+dl{
            margin-top:12px;
          }
          a{
            color:#343434;
            &:hover{
              color:#343434!important;
              text-decoration:none;
            }
          }
        }
        ul{
          margin-top:0;
          margin-left:25px;
          li{
            position:relative;
            padding-left:9px;
            &:before{
              content: "";
              position: absolute;
              top: 0.7em;
              left: 0;
              display: block;
              border-radius: 50%;
              width: 4px;
              height: 4px;
              background: #343434;
            }
          }
        }
      }
    }
  }


}
@media print,
screen and (max-width: 767px) {
  /*
  トップ、voice共通　トップ、blog共通
  -------------------------------------*/
  #Main{
    .contBox{
      .voiceBox{
        padding:30px 30px 22px;
        background:#fff;
        border-radius:10px;
        .img{
          img{
            width:100%;
          }
        }
        .txt{
          padding-top:10px;
          letter-spacing: .05em;
          .details{
            display:inline-block;
            font-size:14px;
            line-height:26px;
            color:#38aed5;
            letter-spacing:.05em;
            border-bottom:1px dashed #38aed5;
          }
          h4{
            font-size:19px;
            letter-spacing:.05em;
            color:#38aed5;
            font-weight:700;
            line-height:32px;
            margin-top: 7px;
          }
          p{
            margin:18px 0 0;
            line-height:26px;
          }
        }
        &+.contSubBox{
          margin-top:40px;
        }
      }
      .entryBlogWrap{
        dl{
          width:100%;
          margin-top:44px;
          text-align:left;
          .img{
            width:100%;
            a{
              display:block;
              img{
                width:100%;
                height: auto;
              }
            }
          }
          .category{
            // margin-top:14px;
            letter-spacing: -.4em;
            li{
              display:inline-block;
              margin-top:14px;
              margin-right:8px;
              a{
                display:block;
                padding:12px 16px;
                line-height:1;
                font-size:14px;
                font-weight:700;
                border-radius:100vh;
                background:#f1f1f1;
                letter-spacing: .05em;
                &:hover{
                  text-decoration:none;
                  background:#d5ebf3;
                  border-radius:100vh;
                }
              }
            }
          }
          .date{
            margin-top:10px;
            font-size:16px;
          }
          dt{
            margin-top:9px;
            a{
              font-weight:700;
              color:#343434;
              letter-spacing:.05em;
              font-size:16px;
            }
          }
        }
      }
      .moreBtn{
        margin-top:71px;
      }
    }
    #SubBlogBox{
      text-align:center;
      padding:56px 0 78px;
      dl{
        text-align:left;
        dt{
          position:relative;
          display:inline-block;
          &:after{
            content: "";
            position: absolute;
            bottom: 3px;
            right: -23px;
            width: 13px;
            height: 6px;
            background: #38aed5;
          }
        }
        dd{
          display:inline-block;
          vertical-align:middle;
          margin-top: 20px;
          ul{
            letter-spacing:-.4em;
            li{
              width:calc(50% - 10px);
              display:inline-block;
              vertical-align:middle;
              margin-left:20px;
              letter-spacing:.05em;
              margin-top:18px;
              &:nth-of-type(2n+1){
                margin-left:0;
              }
              a{
                font-size:16px;
                line-height:26px;
                color:#24386b;
                font-weight:700;
                display:block;
                padding-right: 18px;
                letter-spacing: .05em;
                background-repeat: no-repeat;
                background-position: center right;
                background-image: url(../img/arrow_round.png);
                background-size: 9px auto;
              }
            }
          }
        }
      }
    }
  }

  /*
  トップページ
  -------------------------------------*/
  #Page.pageIndex {
    #MainImg{
      background:#fff;
      padding:0;
      #MainImgInner{
        height:147vw;
        .innerBasic{
          height:100%;
        }
        h2{
          background-color:#24386b;
          background-image:url(../img/top/pagettl_r.png);
          background-size:235px auto;
          background-position: calc(50% - 34px) center;
          width:auto;
          height:315px;
          font-size:26px;
          line-height:52px;
          bottom:-133px;
          top:auto;
          right: 30px;
          left: 30px;
          padding-top:80px;
          letter-spacing:0.01em;
        }
      }
    }
    #Main{
      .topMenu{
        padding-top:186px;
        padding-bottom:50px;
        border-bottom:1px solid #d6e3e7;
        ul{
          text-align:center;
          li{
            &+li{
              margin-top:50px;
            }
            a{
              display:inline-block;
              min-width:180px;
              color:#24386b;
              font-size:20px;
              font-weight:700;
              &:before{
                content:"";
                display:block;
                width:100%;
                height:186px;
                background-position: calc(50% + 17px) 0%;
                background-repeat:no-repeat;
                background-size:180px auto;
                background-image:url(../img/beginner/illust1.png);
              }
            }
            &:nth-child(1){
            }
            &:nth-child(2){
              a{
                min-width:225px;
                &:before{
                  height:162px;
                  background-size:225px auto;
                  background-image:url(../img/illust01.png);
                  background-position:top center;
                }
              }
            }
            &:nth-child(3){
              a{
                min-width:225px;
                &:before{
                  height:166px;
                  background-size:225px auto;
                  background-image:url(../img/illust02.png);
                  background-position:top center;
                }
              }
            }
          }
        }
      }
      .contBox{
        .border_title{
          margin-bottom:20px;
        }
        p{
          text-align:center;
          font-size:20px;
          line-height:32px;
        }
        .linkBtn{
          text-align:center;
        }
      }
      #ContBox01{
        padding:82px 0 0;
        .border_title{
          margin-bottom:24px;
        }
        .topInner{
          margin:72px auto 0;
          padding-bottom:40px;
          position:relative;
          .trainerImg{
            width:100%;
            .innerBox{
              position:relative;
              &:before{
                content:"";
                display:block;
                position:absolute;
                width:calc(100% + 30px);
                left:-15px;
                top:60px;
                bottom:-45px;
                background:#d5ebf3;
              }
            }
            img{
              position:relative;
            }
            dl{
              position:relative;
              width:100%;
              background:#fff;
              text-align:center;
              padding:14px 0 13px;
              dt{
                font-size:12px;
                letter-spacing: .05em;
                display:inline-block;
                vertical-align:middle;
              }
              dd{
                font-size:15px;
                font-weight:700;
                letter-spacing: .01em;
                display:inline-block;
                vertical-align:middle;
                span{
                  font-size:11px;
                  vertical-align:middle;
                }
              }
            }
          }
          .txt{
            text-align:left;
            padding-top:97px;
            p,.txtBox li{
              font-size:16px;
              line-height:32px;
              text-align:left;
              margin-bottom:0;
              letter-spacing:0.05em;
            }
            .txtBox{
              margin-top:10px;
              li{
                position:relative;
                padding-left:10px;
                &+li{
                }
                &:before{
                  content:"";
                  display:block;
                  width:4px;
                  height:4px;
                  border-radius:50px;
                  background:#343434;
                  position:absolute;
                  top:0.8em;
                  left:0;
                }
              }
              &+p{
                margin-top:8px;
              }
            }
            .linkBox{
              text-align:right;
              margin-top:34px;
              .roundLinkBtn{
                margin-left:0;
                margin-top:61px;
                display:block;
                &:nth-of-type(2){
                  margin-top: 72px;
                }
              }
            }
          }
        }
      }
      #ContBox02{
        padding:83px 0 39px;
        .border_title{
          margin-bottom: 22px;
        }
        .cf{
          margin-top:43px;
        }
        .recommend{
          border-radius:10px;
          background:#f7f7f7;
          padding:31px 12px 62px;
          position:relative;
          z-index:0;
          dt{
            margin:0 auto 32px;
            border-bottom:1px solid #f2f2f2;
            font-size:22px;
            font-weight:700;
            color:#38aed5;
            text-align:center;
            position:relative;
            background:#f7f7f7;
            padding-bottom:6px;
            &:before{
              content:"";
              position:absolute;
              top:50%;
              bottom:0;
              left:0;
              right: 0;
              margin:auto;
              border-radius:100px / 10px;
              box-shadow:0 5px 15px -5px rgba(0, 0, 0, 0.3);
              z-index:-1;
            }
          }
          dd{
            font-size:17px;
            line-height:32px;
            font-weight:700;
            ul{
              margin:0 22px;
              li{
                padding-left:30px;
                position:relative;
                a{
                  color:#343434;
                  text-decoration:underline;
                }
                &+li{
                  margin-top:16px;
                }
                &:before{
                  content:"";
                  display:block;
                  width:20px;
                  height:20px;
                  position:absolute;
                  top:0.4em;
                  left:0;
                  background-image:url(../img/check_icon.png);
                  background-size:20px auto;
                  background-repeat:no-repeat;
                  background-position:center center;
                }
              }
            }
          }
          &:after{
            content:"";
            display:block;
            width:110px;
            height:105px;
            background-position:top center;
            background-repeat:no-repeat;
            background-size:110px auto;
            background-image:url(../img/beginner/illust1.png);
            position:absolute;
            right: 59px;
            bottom:-20px;
          }
        }
        .linkBox{
          text-align:right;
          margin-top:27px;
          li{
            display:block;
            margin-top: 77px;
            &+li{
              margin-top:66px;
            }
          }
        }
      }
      #ContBox03{
        padding: 84px 0 87px;
        text-align:center;
        .border_title{
          margin-bottom:23px;
        }
        ul{
          position:relative;
          z-index:0;
          margin-top:65px;
          li{
            width:100%;
            background-color:#fff;
            border-radius:10px;
            position:relative;
            padding:45px 20px 34px;
            &:nth-of-type(2),&:nth-of-type(3){
              padding:30px 20px 34px;
              dl{
                dt{
                  margin-bottom:18px;
                }
              }
            }
            &+li{
              margin-top:49px;
            }
            &:before{
              content:"";
              display:block;
              width:128px;
              height:97px;
              border-radius:50%;
              background-color:#fff;
              position:absolute;
              top:-28px;
              left:0;
              right: 0;
              margin:auto;
              //z-index:-1;
              background-image:url(../img/top/bg_img.png);
              background-size:90px auto;
              background-repeat:no-repeat;
              background-position:top 23px center;
            }
            dl{
              position:relative;
              dt{
                font-size:19px;
                line-height:30px;
                font-weight:700;
                color:#38aed5;
                text-align:center;
                height:90px;
                display:table;
                width:100%;
                margin-bottom:32px;
                letter-spacing:0.02em;
                span{
                  display:table-cell;
                  vertical-align:middle;
                  width:100%;
                }
              }
              dd{
                margin-top:18px;
                font-size:16px;
                line-height:24px;
                text-align:justify;
                img{
                  width:100%;
                }
              }
            }
          }
        }
        .roundLinkBtn{
          margin-top:72px;
          margin-bottom:58px;
          &+p{
            font-size:18px;
            letter-spacing:0.05em;
            margin-bottom:5px;
          }
        }
      }
      #ContBox04{
        padding: 86px 0 40px;
        .border_title{
          margin-bottom: 18px;
        }
        .owl-carousel{
          padding-top:90px;
        }
        .owl-item{
          width:305px!important;
        }
        .owl-nav{
          position:absolute;
          top:11px;
          left:50%;
          width: 168px;
          transform: translateX(-50%);
          .owl-next,.owl-prev{
            width:48px;
            height:48px;
            border-radius:50%;
            background-color:#f7f7f7;
            background-repeat: no-repeat;
            background-position: center center;
            background-image: url(../img/arrow_next.png);
            background-size: 8px auto;
            margin:0 18px;
            span{
              display:none;
            }
          }
          .owl-prev{
            background-image: url(../img/arrow_prev.png);
          }
        }
        .popup-image{
          position:relative;
          a{
            position:absolute;
            width:65px;
            height:65px;
            text-indent:-9999px;
            display:block;
            background-repeat: no-repeat;
            background-position: center center;
            background-image: url(../img/popup_icon.png);
            background-size: 65px auto;
            bottom:15px;
            right: 15px;
          }
        }
        .txt{
          width:100%;
          background:#f7f7f7;
          border-radius:10px;
          padding:15px 25px;
          margin-top:10px;
          font-size:16px;
          font-weight:700;
          line-height:26px;
        }
        .linkBtn{
          margin-top:18px;
        }
      }
      #ContBox05{
        padding: 82px 0 91px;
        .border_title{
          margin-bottom:23px;
        }
        .voiceBox{
          &:first-of-type{
            margin-top:43px;
          }
          .txt{
            padding-top:34px;
          }
          p{
            font-size:16px;
            line-height:26px;
            text-align:justify;
          }
          &+.voiceBox{
            margin-top: 31px;
          }
        }
      }
      #ContBox06{
        padding: 84px 0 87px;
        .border_title{
          margin-bottom: 23px;
        }
        .entryBlogWrap{
          dl{
            margin-top:40px;
            &:first-of-type{
              margin-top:53px;
            }
          }
        }
        .linkBtn{
          margin-top:25px;
        }
        #SubBlogBox{
          padding:15px 0 0;
          ul{
            li{
            }
          }
        }
      }
      #ContBox07{
        padding: 84px 0 65px;
        .border_title{
          margin-bottom: 23px;
        }
        .gMap{
          width:100%;
          height:350px;
          padding:10px;
          background:#fff;
          margin-top:37px;
          iframe{
            width:100%;
            height:330px;
          }
        }
        .linkTxt{
          text-align:right;
          margin-top:20px;
          a{
            color:#24386b;
            letter-spacing:.05em;
            font-size:16px;
            font-weight:700;
            &:after{
              content: "";
              display: inline-block;
              margin-left:10px;
              width: 13px;
              height: 13px;
              background-repeat: no-repeat;
              background-position: center center;
              background-image: url(../img/about/maplink.png);
              background-size: 13px auto;
              vertical-align: -1px;
            }
          }
        }
        .roundLinkBtn{
          margin: 54px 30px 50px 0;
        }
      }
    }
    .mfp-close{
      position: absolute;
      top: auto;
      bottom:60px;
      right: 24px;
      padding: 0;
      width: 64px;
      height: 64px;
      background: url(../img/popup_icon_minus.png) no-repeat center center;
      background-size: 64px auto;
      opacity: 1;
      font-size: 0;
      &:hover{
        opacity:0.7;
      }
    }
  }

  /*
  パーソナルラボRについて
  -------------------------------------*/
  #PageAbout.pageIndex {
    #MainImg{
      #MainImgInner{
        h2{
          padding-top:56px;
        }
      }
    }
    #Main{
      #ContBox01{
        padding:43px 0 32px;
        .catch_title{
          margin-bottom:20px;
        }
        .topImgBox{
          .txt{
            p{
              letter-spacing:.05em;
              margin-bottom:12px;
              line-height:30px;
            }
          }
          .img{
            margin-top:31px;
          }
        }
        .linkBox{
          text-align:right;
          margin-top: 21px;
          li{
            display:block;
            margin: 71px 30px 69px 0;
            &+li{
              margin-top:18px;
            }
          }
        }
      }
      #ContBox02{
        padding:73px 0 59px;
        .border_title{
          margin-bottom:26px;
        }
        p{
          letter-spacing:.05em;
          margin-bottom:40px;
          line-height:30px;
        }
        .imgList01{
          li{
            &:nth-child(2n){
              // margin-left:50px;
            }
            &:nth-child(1),&:nth-child(2){
              margin-bottom:30px;
            }
            dl{
              dt{
                color:#38aed5;
                font-weight:700;
                text-align:center;
                font-size:19px;
                letter-spacing:.05em;
                padding:16px 0 10px;
              }
              dd{
                &:nth-of-type(2){
                  margin-bottom: 24px;
                  padding: 0 25px;
                  line-height: 24px;
                  letter-spacing: .04em;
                }
              }
            }
          }
        }
        .imgList02{
          margin-top:70px;
          li{
            width:calc(50% - 10px);
            float:left;
            margin-right:20px;
            &:nth-child(2n){
              margin-right:0;
            }
            dl{
              dt{
                height:52px;
                text-align:center;
                color:#38aed5;
                font-size:18px;
                font-weight:700;
                padding-top:11px;
                letter-spacing: .05em;
              }
            }
          }
        }
      }
      #ContBox03{
        padding:62px 0 90px;
        .border_title{
          margin-bottom:42px;
        }
        .mapBox{
          .gMap{
            border:10px solid #fff;
            height:345px;
            iframe{
              width:100%;
              height:100%;
            }
          }
          .bigmap{
            font-size:16px;
            font-weight:700;
            text-align:right;
            margin-top:21px;
            a{
              color:#24386b;
              letter-spacing:.05em;
              &:after{
                content: "";
                display: inline-block;
                margin-left:8px;
                width: 13px;
                height: 13px;
                background-repeat: no-repeat;
                background-position: center center;
                background-image: url(../img/about/maplink.png);
                background-size: 13px auto;
                vertical-align: -1px;
              }
            }
          }
          .access{
            background:#fff;
            padding: 23px 0 21px 20px;
            letter-spacing:.05em;
            margin-top:25px;
            margin-bottom:68px;
            dt{
              font-size:17px;
              color:#38aed5;
              font-weight:700;
              letter-spacing: .01em;
              &:before{
                content: "";
                display: inline-block;
                margin-right: 16px;
                width: 18px;
                height: 20px;
                background-repeat: no-repeat;
                background-position: center center;
                background-image: url(../img/about/icon_kokyo.png);
                background-size: 18px auto;
                vertical-align: -4px;
              }
            }
            dd{
              margin-top:12px;
              letter-spacing: .14em;
              line-height:24px;
              &:nth-of-type(2){
                margin-top:6px;
              }
              span{
                position:relative;
                margin-left:54px;
                &:before{
                  position:absolute;
                  margin-left:-54px;
                  top:9px;
                  content: "";
                  display: inline-block;
                  width: 45px;
                  height: 1px;
                  background: #343434;
                }
                a{
                  color:#24386b;
                  font-weight:700;
                  &:after{
                    content: "";
                    display: inline-block;
                    margin-left: 6px;
                    width: 13px;
                    height: 13px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-image: url(../img/about/maplink.png);
                    background-size: 13px auto;
                    vertical-align: -1px;
                  }
                }
              }
              &:nth-of-type(2){
                span{
                  margin-left:54px;
                  &:before{
                    margin-left:-54px;
                    width: 45px;
                  }
                }
              }
            }
            &.car{
              margin: 25px 0 29px;
              dt{
                &:before{
                  background-image: url(../img/about/icon_car.png);
                  background-size: 22px auto;
                  width:22px;
                }
              }
              dd{
                margin-top:12px;
                line-height:24px;
                &:nth-of-type(2){
                  letter-spacing:.05em;
                  margin-top:7px;
                }
                span{
                  margin-left:54px;
                  letter-spacing:.14em;
                  &:before{
                    margin-left:-54px;
                    width: 45px;
                  }
                }
                &:nth-of-type(2){
                  span{
                    margin-left:54px;
                    &:before{
                      margin-left:-54px;
                      width: 45px;
                    }
                  }
                }
              }
            }
          }
          &.mapBox02{
            .left{
              .gMap{
                //height:225px;
                height:310px;
              }
              .bigmap{
                margin-top:19px;
                margin-right: 1px;
              }
            }
            .right{
              // margin-top:-105px;
              .table{
                width:100%;
                tr{
                  th{
                    width:41%;
                    border-bottom:1px solid #343434;
                    text-align:center;
                    vertical-align:middle;
                    line-height:24px;
                    padding:24px 0;
                    position:relative;
                    &:after{
                      content:"";
                      display:block;
                      width:8px;
                      height:calc(100% + 4px);
                      background:#f0f8fb;
                      position:absolute;
                      top:-1px;
                      right: 0;
                    }
                    &:nth-of-type(2){
                      border-top:1px solid #343434;
                    }
                  }
                  td{
                    margin-left:8px;
                    width:59%;
                    border-bottom:1px dashed #343434;
                    padding:23px 19px;
                    line-height:24px;
                    vertical-align:middle;
                  }
                  &:first-child{
                    th{
                      //text-indent:-9999px;
                      height:206px;
                      /*background-repeat: no-repeat;
                      background-position: top 41px center;
                      background-image: url(../img/about/rlogo.png);
                      background-size:73px auto;*/
                      img {
                        width: 75px;
                      }
                    }
                    td{
                      text-align:center;
                      padding:0;
                      img{
                        width:128px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  トレーナー紹介
  -------------------------------------*/
  #PageTrainer.pageIndex {
    #Main{
      #ContBox01{
        padding:43px 0 43px;
        .catch_title{
          margin-bottom:20px;
        }
        .topImgBox{
          .txt{
            p{
              letter-spacing:.05em;
              margin-bottom: 16px;
              line-height:30px;
            }
          }
          .img{
            margin-top: 30px;
            .trainerImg{
              width:100%;
              img{
                width:100%;
              }
              dl{
                background:#fff;
                text-align:center;
                padding:15px;
                line-height:1;
                dt{
                  display:inline-block;
                  font-size:12px;
                  letter-spacing: .05em;
                  margin-right: 5px;
                }
                dd{
                  display:inline-block;
                  font-size:15px;
                  font-weight:700;
                  margin-top: 3px;
                  letter-spacing: .01em;
                  span{
                    font-size:11px;
                  }
                }
              }
            }
            .contSubBox{
              margin-top:39px;
              &:last-child{
                margin-top:30px;
              }
              h4{
                position:relative;
                margin-left:35px;
                font-weight:700;
                font-size:14px;
                letter-spacing: .01em;
                &:before{
                  content: "";
                  position:absolute;
                  margin-right: 17px;
                  width: 20px;
                  height: 3px;
                  top:9px;
                  background: #343434;
                  margin-left:-35px;
                }
              }
              ul{
                margin-left:34px;
                margin-top:7px;
                letter-spacing: 0.01em;
                line-height:20px;
              }
              p{
                margin-left:34px;
                margin-top:5px;
                font-size:14px;
                line-height:20px;
                letter-spacing: .05em;
              }
            }
          }
        }
        .linkBox{
          margin-top: 26px;
          text-align:right;
          li{
            display:block;
            margin: 72px 30px 64px 0;
            &+li{
              margin-top:18px;
            }
          }
        }
      }
      #ContBox02{
        padding-bottom:69px;
        padding-top:73px;
        .contSubBox{
          margin:0 auto;
          h4{
            font-size:30px;
            line-height:48px;
            color:#38aed5;
            text-align:center;
            margin-top:42px;
            margin-bottom:32px;
            letter-spacing:.01em;
          }
          p{
            margin-bottom: 16px;
            line-height:30px;
            letter-spacing: .05em;
          }
          &+.contSubBox{
            margin-top:52px;
          }
          .imgTxt{
            .left{
              img{
                width:100%;
                margin-top: 6px;
                margin-bottom:43px;
              }
            }
            .right{
              img{
                width:100%;
                margin-top: 6px;
                margin-bottom:43px;
              }
            }
            &+.imgTxt{
              margin-top:15px;
            }
          }
          img {
            &.alignright {
              width: 100% !important;
              float: none;
              margin: 0.5em 0 0.5em 0;
            }
            &.alignleft {
              width: 100% !important;
              float: none;
              margin: 0.5em 0 0.5em 0;
            }
            &.aligncenter {
              width: 100% !important;
            }
          }
        }
      }
    }
  }

  /*
  はじめての方へ
  -------------------------------------*/
  #PageBeginner.pageIndex {
    #Main{
      #ContBox01{
        padding-top:43px;
        padding-bottom:78px;
        .catch_title{
          margin-bottom:20px;
        }
        p{
          text-align:center;
          letter-spacing:.05em;
          line-height:30px;
        }
        ul{
          margin-top:42px;
          li{
            dl{
              padding-top:42px;
              dt{
                font-size:20px;
                font-weight:700;
                margin-top:246px;
                span{
                  display:block;
                  font-size:16px;
                }
              }
              dd{
              }
            }
            height:400px;
            background-color:#fff;
            border-radius:10px;
            color:#38aed5;
            text-align:center;
            background-repeat: no-repeat;
            background-position: top 104px center;
            background-image: url(../img/beginner/illust1.png);
            background-size:186px auto;
            &:nth-child(1){
              dl{
                dt{
                  margin-top:229px;
                }
              }
            }
            &:nth-child(2){
              background-image: url(../img/beginner/illust2.png);
              background-position: top 104px center;
              background-size:254px auto;
            }
            &:nth-child(3){
              background-image: url(../img/beginner/illust3.png);
              background-position: top 104px center;
              background-size:220px auto;
            }
            &+li{
              margin-top:25px;
            }
          }
        }
      }
      #ContBox02{
        padding-bottom:0;
        padding-top: 73px;
        .border_title{
          margin-bottom:12px;
        }
        .imgTxtBox{
          padding:40px 0 34px;
          &:first-child{
            padding-top:29px;
          }
          &+.imgTxtBox{
            border-top:1px dashed #38aed5;
          }
          .img{
            margin-bottom:29px;
          }
          .txt{
            h4{
              color:#38aed5;
              font-size:22px;
              font-weight:700;
              text-indent:-100px;
              margin-left:100px;
              letter-spacing: .05em;
              line-height:32px;
              span{
                vertical-align:1px;
                margin-right:14px;
                img{
                  width:85px;
                }
              }
            }
            p{
              margin-top: 7px;
              margin-bottom:0;
              letter-spacing: .05em;
              line-height:30px;
            }
          }
        }
        .center{
          margin-top:33px;
          p{
            text-align:center;
            font-size:18px;
            letter-spacing:.05em;
            margin-bottom:13px;
          }
        }
      }
      #ContBox03{
        padding-bottom:53px;
        padding-top:76px;
        .imgList{
          margin-top:40px;
          li{
            dl{
              dt{
                text-align:center;
                color:#38aed5;
                font-size:18px;
                line-height:26px;
                font-weight:700;
                padding:17px 0 34px;
                letter-spacing: .05em;
              }
            }
          }
        }
      }
    }
  }

  /*
  共通要素
  -------------------------------------*/
  #PagePersonalTraining,
  #PagePain,
  #PageDiet,
  #PageCouese{
    #Main{
      #ContBox01{
        padding:43px 0 67px;
        .catch_title{
          letter-spacing:0.09em;
          margin-bottom:19px;
        }
      }
      .topImgBox{
        .txt{
          p{
            letter-spacing:.05em;
            margin-bottom: 30px;
            line-height:30px;
          }
        }
        .img{
          img{
            max-width:100%;
          }
        }
      }
      .recommend{
        border-radius:10px;
        background:#fff;
        padding:29px 15px 34px;
        position:relative;
        z-index:0;
        margin-top:22px;
        dt{
          margin:0 auto 33px;
          border-bottom:1px solid #f2f2f2;
          font-size:22px;
          font-weight:700;
          color:#38aed5;
          text-align:center;
          position:relative;
          background:#fff;
          padding-bottom:6px;
          &:before{
            content:"";
            //display:block;
            //width:85%;
            //height:15px;
            position:absolute;
            top:50%;
            bottom:0;
            left:4px;
            right: 4px;
            margin:auto;
            border-radius:100px / 10px;
            box-shadow:0 3px 15px -5px rgba(0, 0, 0, 0.3);
            //background: 0 15px 10px rgba(0, 0, 0, 0.7);
            z-index:-1;
          }
        }
        dd{
          font-size:18px;
          line-height:32px;
          font-weight:700;
          ul{
            margin-left:26px;
            li{
              padding-left:10px;
              position:relative;
              &:before{
                content:"";
                display:block;
                width:4px;
                height:4px;
                border-radius:50px;
                position:absolute;
                background:#343434;
                top:0.6em;
                left:0;
              }
            }
          }
        }
      }
      .linkBox{
        text-align:right;
        margin-top: 43px;
        li{
          margin-top:34px;
          margin-bottom:34px;
        }
      }
      .imgTxtBox{
        padding:40px 0 30px;
        &:first-child{
          padding-top:29px;
        }
        &+.imgTxtBox{
          border-top:1px dashed #38aed5;
        }
        .img{
          margin-bottom:32px;
        }
        .txt{
          h4{
            color:#38aed5;
            font-size:22px;
            font-weight:700;
            letter-spacing: .05em;
            line-height:32px;
          }
          p{
            margin-top: 11px;
            margin-bottom:0;
            letter-spacing: .05em;
            line-height:30px;
          }
        }
      }
      .priceBox{
        margin-top:47px;
        .priceInner{
          width:100%;
          margin:0 auto;
          background-color:#fff;
          padding:43px 15px 63px;
          margin-bottom:50px;
          .priceDetaile{
            font-weight:700;
            font-size:20px;
            text-align:center;
            margin-bottom:25px;
            span{
              font-size:12px;
              &.price{
                font-size:30px;
              }
            }
            ul{
              margin-top:8px;
              li{
                &+li{
                  position: relative;
                  padding-top: 19px;
                  &:before{
                    content:"\02f";
                    display:block;
                    position: absolute;
                    font-size: 14px;
                    top: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                  }
                }
              }
            }
          }
          .note{
            text-align:center;
            margin-top:20px;
            li{
              font-size:16px;
              &+li{
                margin-top:8px;
              }
            }
          }
          .table{
            width:100%;
            tr{
              th,td{
                vertical-align:middle;
                font-size:16px;
                line-height:30px;
              }
              th{
                width:28%;
                text-align:center;
                border-top:1px solid #343434;
                padding-right:8px;
                position:relative;
                font-weight:700;
                &:after{
                  content:"";
                  display:block;
                  width:8px;
                  height:calc(100% + 2px);
                  background:#fff;
                  position:absolute;
                  top:-1px;
                  right: 0;
                }
              }
              td{
                padding:20px 0 20px 21px;
                border-top:1px dashed #343434;
                ul{
                  margin-bottom:7px;
                  li{
                    position:relative;
                    padding-left:12px;
                    &:before{
                      content:"";
                      display:block;
                      width:4px;
                      height:4px;
                      background:#343434;
                      border-radius:50px;
                      position:absolute;
                      top:0.7em;
                      left:0;
                    }
                    &+li{
                      margin-top:10px;
                    }
                  }
                }
              }
              &:last-of-type{
                th{
                  border-bottom:1px solid #343434;
                }
                td{
                  border-bottom:1px dashed #343434;
                }
              }
            }
          }
        }
        p{
          text-align:center;
          font-size:18px;
          line-height:1;
          margin-bottom:10px;
          letter-spacing:0.04em;
        }
      }
      .blogLink{
        text-align:center;
        margin-top:30px;
      }
    }
  }
  #PagePain.pageIndex,
  #PageDiet.pageIndex{
    #MainImg{
      #MainImgInner{
        background-image:url(../img/header/pagettl3_sp.jpg);
        h2{
          padding-top: 35px;
          span{
            display:block;
            font-size:18px;
            line-height:24px;
            letter-spacing: .05em;
            &:after{
              content: "";
              display: block;
              margin:15px auto;
              width: 70px;
              height: 1px;
              background: #fff;
            }
          }
        }
      }
    }
  }

  /*
  パーソナルトレーニングについて
  -------------------------------------*/
  #PagePersonalTraining.pageIndex {
    #MainImg{
      #MainImgInner{
        .innerBasic h2{
          padding-top:56px;
        }
      }
    }
    #Main{
      #ContBox01{
        .linkBox{
          .roundLinkBtn{
            width:calc(100% - 30px);
          }
        }
      }
      #ContBox02{
        padding-top:71px;
        padding-bottom:0;
        .border_title{
          margin-bottom:11px;
        }
      }
      #ContBox03{
        padding:34px 15px 80px;
        .border_title{
          margin-bottom:40px;
        }
        .openBox{
          background-color:#f7f7f7;
          width:100%;
          margin:0 auto;
          padding:40px 15px 30px;
          dl{
            dt{
              font-size:20px;
              font-weight:700;
              position:relative;
              line-height:32px;
              padding:4px 54px 4px 37px;
              background-image:url(../img/check_icon.png);
              background-repeat:no-repeat;
              background-position:center left;
              background-size:22px auto;
              span{
                position:absolute;
                top:0;
                bottom:0;
                right: 0;
                margin:auto;
                text-indent:-9999px;
                display:block;
                width:40px;
                height:40px;
                background-color:#d5ebf3;
                border-radius:50px;
                background-image:url(../img/plus.png);
                background-repeat:no-repeat;
                background-position:center center;
                background-size:20px auto;
                cursor:pointer;
              }
              &.active{
                span{
                  background-image:url(../img/minus.png);
                }
              }
            }
            dd{
              display:none;
              margin-top:16px;
              padding-bottom:7px;
              p{
                font-size:16px;
                line-height:30px;
                letter-spacing:0.08em;
              }
              ul{
                margin-top:22px;
                li{
                  display:block;
                  font-size:17px;
                  font-weight:700;
                  color:#38aed5;
                  margin-bottom:10px;
                  span{
                    display:inline-block;
                    background-color:#fff;
                    padding:0 18px;
                    border-radius:21px;
                    line-height:42px;
                  }
                }
              }
            }
            &+dl{
              margin-top:25px;
            }
          }
        }
      }
      #ContBox04{
        padding:62px 0;
      }
      #ContBox05{
        padding:74px 0 64px;
      }
    }
  }

  /*
  整体（痛みの改善）
  -------------------------------------*/
  #PagePain.pageIndex {
    #Main{
      #ContBox01{
        padding:70px 0 80px;
        .catch_title{
          letter-spacing:0.03em;
        }
        .topImgBox{
          .txt{
            p{
              letter-spacing:.05em;
              margin-bottom: 12px;
              &+p{
                margin-top:30px;
              }
            }
          }
          .img{
            margin-top:40px;
          }
        }
      }
      #ContBox02{
        padding:74px 0 40px;
        .border_title{
          margin-bottom:10px;
        }
      }
      #ContBox03{
        padding:62px 0 87px;
        .priceBox{
          .priceInner{
            padding:43px 15px 44px;
            .priceDetaile{
              margin-bottom:0;
              .price{
                position:relative;
                top:2px;
              }
            }
          }
        }
      }
      #ContBox04{
        padding:74px 0 64px;
      }
    }
  }

  /*
  集中ダイエット
  -------------------------------------*/
  #PageDiet.pageIndex {
    #Main{
      #ContBox01{
        padding:70px 0 80px;
        .catch_title{
          letter-spacing:0.05em;
        }
        .topImgBox{
          .txt{
            p{
              letter-spacing:.05em;
              margin-bottom: 12px;
            }
          }
          .img{
            margin-top:40px;
          }
        }
      }
      #ContBox02{
        padding:75px 0 45px;
        .border_title{
          margin-bottom:10px;
        }
      }
      #ContBox03{
        padding:60px 0 87px;
        .priceBox{
          .priceInner{
            padding:50px 15px 58px;
            .priceDetaile{
              margin-bottom:0;
            }
            .table{
              margin-top:45px;
            }
          }
        }
      }
      #ContBox04{
        padding:74px 0 64px;
      }
    }
  }

  /*
  料金・コース
  -------------------------------------*/
  #PageCouese.pageIndex {
    #Main{
      .contBox{
        p{
          &:first-child{
            font-size:16px;
            line-height:30px;
          }
        }
        .imgBox{
          margin-top:33px;
          width:100%;
          .left{
            background: #f7f7f7;
            border-radius:10px 10px 0 0;
            overflow:hidden;
            text-align:right;
            padding-bottom:39px;
            .recommend{
              background: #f7f7f7;
              border-radius:0;
              padding:8px 15px 0 15px;
              dt{
                background: #f7f7f7;
                font-size:22px;
                text-align:center;
                margin:0 auto 31px;
              }
              dd{
                text-align:left;
                font-size:17px;
                line-height:32px;
                ul{
                  margin-left:24px;
                  margin-right:24px;
                  li{
                    &:before{
                      top:0.8em;
                    }
                  }
                }
              }
            }
            .roundLinkBtn{
              margin:31px 32px 19px 24px;
              &:after{
                margin-left: 18px;
                width: 7px;
                height: 12px;
                background-size: 7px auto;
              }
              a{
                font-size:16px;
                &:before{
                  top: -10px;
                  right: -41px;
                  width: 78px;
                  height: 78px;
                }
              }
            }
          }
          .right{
            overflow:hidden;
            border-radius:0 0 10px 10px;
            position:relative;
          }
        }
        .priceBox{
          margin-top:30px;
          padding:65px 15px 75px;
          .priceTit{
            font-size:26px;
            font-weight:700;
            text-align:center;
            margin-bottom:40px;
          }
          p{
            font-size:16px;
          }
          .applyBtn{
            max-width:345px;
            width:100%;
          }
        }
      }
      #ContBox01{
        padding:44px 0 72px;
        .catch_title{
          letter-spacing:0.05em;
          margin-bottom:20px;
        }
        p{
          text-align:center;
        }
        ul{
          text-align:center;
          margin-top:40px;
          letter-spacing:-0.4em;
          li{
            display:inline-block;
            width:50%;
            vertical-align:bottom;
            letter-spacing:0.1em;
            &:nth-child(n+3){
              margin-top:43px;
            }
            a{
              display:inline-block;
              min-width:112px;
              color:#24386b;
              font-size:17px;
              line-height:24px;
              font-weight:700;
              padding-bottom:25px;
              background-position:bottom 4px center;
              background-repeat:no-repeat;
              background-size:16px auto;
              background-image:url(../img/arrow_b.png);
              >span{
                display:table;
                width:100%;
                height:48px;
                position:relative;
                span{
                  display:table-cell;
                  vertical-align:middle;
                }
              }
              &:before{
                content:"";
                display:block;
                width:100%;
                height:134px;
                background-position:top center;
                background-repeat:no-repeat;
                background-size:100% auto;
                background-image:url(../img/beginner/illust1.png);
              }
            }
            &:nth-child(2){
              a{
                min-width:159px;
                &:before{
                  height:138px;
                  background-size:159px auto;
                  background-image:url(../img/beginner/illust2.png);
                }
              }
            }
            &:nth-child(3){
              a{
                min-width:140px;
                padding-bottom:14px;
                &:before{
                  height:115px;
                  background-size:140px auto;
                  background-image:url(../img/illust01.png);
                }
              }
            }
            &:nth-child(4){
              a{
                min-width:137px;
                padding-bottom:14px;
                &:before{
                  height:112px;
                  background-size:137px auto;
                  background-image:url(../img/illust02.png);
                }
              }
            }
            &:nth-child(5){
              margin-top:33px;
              a{
                padding-bottom:14px;
                min-width:139px;
                &:before{
                  height:129px;
                  background-size:139px auto;
                  background-image:url(../img/illust03.png);
                }
              }
            }
          }
        }
      }
      #ContBox02{
        padding:72px 0 73px;
        .imgBox{
          .left{
            background: transparent;
            overflow:hidden;
            padding-right:0;
            padding-bottom:0;
          }
          .right{
            margin-top:3px;
          }
        }
        .priceBox{
          .priceInner{
            padding:49px 15px 19px;
          }
        }
      }
      #ContBox03{
        padding:0 0 74px;
      }
      #ContBox04{
        padding:0 0 76px;
        .priceBox{
          .priceInner{
            padding:46px 15px 16px;
          }
        }
      }
      #ContBox05{
        padding:0 0 75px;
        .priceBox{
          .priceInner{
            padding:50px 15px 66px;
            .priceDetaile{
              margin-bottom:9px;
            }
            .table{
              margin-top:43px;
            }
          }
        }
      }
      #ContBox06{
        padding:0 0 90px;
        .imgBox{
          .left{
            padding-bottom:30px;
          }
        }
        .priceBox{
          .priceInner{
            padding:45px 15px 61px;
            .priceDetaile{
              margin-bottom:9px;
            }
            .table{
              margin-top:25px;
            }
          }
        }
      }
    }
  }

  /*
  お客様の声共通
  -------------------------------------*/
  #Main{
    #SubVoiceBox{
      text-align:center;
      margin: 42px 0 37px;
      .roundLinkBtn{
        // margin-left:0;
      }
      dl{
        text-align:left;
        margin-top:46px;
        dt{
          position:relative;
          display:inline-block;
          &:after{
            content: "";
            position: absolute;
            bottom: 3px;
            right: -23px;
            width: 13px;
            height: 6px;
            background: #38aed5;
          }
        }
        dd{
          display:inline-block;
          vertical-align:middle;
          margin-top: 20px;
          ul{
            letter-spacing:-.4em;
            li{
              width:calc(50% - 10px);
              display:inline-block;
              vertical-align:middle;
              margin-left:20px;
              letter-spacing:.05em;
              margin-top:18px;
              &:nth-of-type(2n+1){
                margin-left:0;
              }
              a{
                font-size:16px;
                line-height:26px;
                color:#24386b;
                font-weight:700;
                display:block;
                padding-right: 25px;
                letter-spacing: .05em;
                background-repeat: no-repeat;
                background-position: center right;
                background-image: url(../img/arrow_round.png);
                background-size: 9px auto;
              }
            }
          }
        }
      }
    }
    .entryVoiceWrap{
      letter-spacing:-.4em;
      text-align:left;
      dl{
        letter-spacing:.05em;
        width:calc(50% - 8px);
        display:inline-block;
        margin-right:16px;
        margin-top:26px;
        vertical-align:top;
        &:nth-of-type(2n){
          margin-right:0;
        }
        dt{
          text-align:center;
          font-weight:700;
          margin-top:12px;
          letter-spacing:.05em;
          a{
            color:#343434;
          }
        }
        dd{
          border:1px solid #e9e9e9;
          a{
            display:block;
            position:relative;
          }
        }
      }
    }
    .moreBtn{
      margin-top:98px;
      margin-left: 11px;
      &:after{
        background-image: url(../img/voice/icon_plus.png);
        background-size: 20px auto;
        width:20px;
        margin-left: 37px;
      }
      a{
        &:before{
          content: "";
          position: absolute;
          top: -6px;
          right: -77px;
          bottom: 0;
          border-radius: 100%;
          width: 60px;
          height: 60px;
          background: #d5ebf3;
        }
      }
    }
  }

  /*
  お客様の声
  -------------------------------------*/
  #PageVoice.pageIndex {
    #Main{
      #ContBox01{
        padding:44px 0 87px;
        .catch_title{
          margin-bottom:25px;
        }
      }
      #ContBox02{
        padding:68px 0 40px;
        text-align:center;
          dl {
            dd {
              img {
                height: auto;
              }
            }
          }
      }
    }
  }

  /*
  お客様の声（カテゴリアーカイブ）
  -------------------------------------*/
  #PageVoice.pageArchive {
    #Main{
      #SubVoiceBox{
        margin-top:0;
        padding:26px 0 73px;
        dl{
          margin-top:26px;
        }
      }
      .contBox{
        text-align:center;
        padding:36px 0 41px;
        .border_title{
          margin-bottom: 15px;
        }
        .entryVoiceWrap{
          text-align:left;
          dl {
            dd {
              img {
                height: auto;
              }
            }
          }
        }
        .moreBtn{
          margin-top:93px;
        }
      }
    }
  }

  /*
  お客様の声（記事詳細）
  -------------------------------------*/
  #PageVoice.pageEntry {
    #Main{
      #SubVoiceBox{
        margin-top:0;
        padding:26px 0 73px;
        dl{
          margin-top:26px;
        }
      }
      .contBox{
        padding:43px 0 85px;
        .infoBox{
          text-align:center;
          .date{
            font-size:18px;
            text-align:center;
            margin-bottom:10px;
          }
          .entryTitle{
            font-size:30px;
          }
          .details{
            font-weight:700;
            margin-top:9px;
          }
          .category{
            // margin-top:15px;
            letter-spacing: -.4em;
            li{
              display:inline-block;
              margin-top:15px;
              a{
                display:block;
                padding:12px 16px;
                line-height:1;
                font-size:14px;
                font-weight:700;
                border-radius:100vh;
                background:#f1f1f1;
                letter-spacing: .05em;
                &:hover{
                  text-decoration:none;
                  background:#d5ebf3;
                  border-radius:100vh;
                }
              }
              &+li{
                margin-left:8px;
              }
            }
          }
        }
        .entryImg{
          margin:59px 0;
          text-align:center;
          img{
            border: 1px solid #e9e9e9;
            height: auto;
          }
        }
        .pageMove{
          text-align:center;
          margin-top:64px;
          ul{
            width:345px;
            position:relative;
            margin:0 auto;
            li{
              display:inline-block;
              a{
                color:#24386b;
                font-size:16px;
                font-weight:700;
                letter-spacing:.05em;
              }
              &.prev{
                position:absolute;
                left:0;
                a{
                  &:before{
                    content: "";
                    display: inline-block;
                    margin-right: 17px;
                    width: 8px;
                    height: 16px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-image: url(../img/voice/arrow_before.png);
                    background-size: 8px auto;
                    vertical-align: middle;
                  }
                }
              }
              &.back{
                a{
                  &:after{
                    content: "";
                    display: inline-block;
                    margin-left: 5px;
                    width: 18px;
                    height: 16px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-image: url(../img/voice/arrow_back.png);
                    background-size: 18px auto;
                    vertical-align: middle;
                  }
                }
              }
              &.next{
                position:absolute;
                right:0;
                a{
                  &:after{
                    content: "";
                    display: inline-block;
                    margin-left: 17px;
                    width: 8px;
                    height: 16px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-image: url(../img/voice/arrow_next.png);
                    background-size: 8px auto;
                    vertical-align: middle;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  公式ブログ
  -------------------------------------*/
  #PageBlog.pageIndex {
    #Main{
      .contBox{
        text-align:center;
        padding:35px 0 42px;
        dl {
          dd.img {
            img {
              height: auto;
            }
          }
        }
      }
    }
  }

  /*
  公式ブログ（カテゴリアーカイブ）
  -------------------------------------*/
  #PageBlog.pageArchive {
    #Main{
      .contBox{
        text-align:center;
        padding:73px 0 42px;
        .border_title{
          margin-bottom:41px;
        }
        .entryBlogWrap{
          dl{
            &:first-child{
              margin-top:0;
            }
          }
        }
        dl {
          dd.img {
            img {
              height: auto;
            }
          }
        }
      }
    }
  }

  /*
  公式ブログ（記事詳細）
  -------------------------------------*/
  #PageBlog.pageEntry {
    #Main{
      .contBox{
        padding:81px 0 66px;
        text-align:center;
        .infoBox{
          text-align:center;
          margin-bottom: 30px;
          .date{
            font-size:18px;
            text-align:center;
            margin-bottom:10px;
          }
          .entryTitle{
            font-size:30px;
          }
          .details{
            font-weight:700;
            margin-top:9px;
          }
          .category{
            margin-top:10px;
            letter-spacing: -.4em;
            li{
              display:inline-block;
              a{
                display:block;
                padding:12px 16px;
                line-height:1;
                font-size:14px;
                font-weight:700;
                border-radius:100vh;
                background:#f1f1f1;
                letter-spacing: .05em;
                &:hover{
                  text-decoration:none;
                  background:#d5ebf3;
                  border-radius:100vh;
                }
              }
              &+li{
                margin-left:8px;
              }
            }
          }
        }
        .entryImg{
          margin:59px 0;
          text-align:center;
          img{
            border: 1px solid #e9e9e9;
            height: auto;
          }
        }
        .roundLinkBtn{
          margin: 64px 30px 50px 0;
        }
      }
    }
  }

  /*
  form 共通
  -------------------------------------*/
  #PageContact,
  #PageReservation {
    #Main{
      #ContBox01{
        padding:44px 0 82px;
        .catch_title{
          margin-bottom:19px;
          letter-spacing:0.05em;
        }
        p{
          font-size:16px;
          line-height:30px;
          text-align:center;
          letter-spacing:0.05em;
        }
        .formTel{
          background:#fff;
          width:100%;
          margin:35px auto 0;
          border-radius:10px;
          dl{
            text-align:center;
            padding:44px 0 44px;
            dt{
              font-size:18px;
              color:#36466f;
              font-weight:700;
              position:relative;
              letter-spacing:0.05em;
              padding-bottom:29px;
              &:before{
                content:"";
                display:inline-block;
                margin-right:9px;
                width:24px;
                height:25px;
                vertical-align:middle;
                background-image:url(../img/footer/iconTel.png);
                background-repeat:no-repeat;
                background-position:center center;
                background-size:24px auto;
              }
            }
            dd{
              color:#36466f;
              font-weight:700;
              line-height:1;
              padding-top:29px;
              display:inline-block;
              border-top:1px solid #24386b;
              a{
                font-size:36px;
                color:#36466f;
                letter-spacing:0.05em;
                text-decoration:none;
                &:hover{
                  opacity:1;
                }
              }
              >span{
                font-size:18px;
                display:block;
                margin-top:12px;
                letter-spacing:0.05em;
                span{
                  font-size:14px;
                }
              }
            }
          }
        }
      }
      #ContBox02{
        padding:71px 0 87px;
        .border_title{
          margin-bottom:19px;
        }
      }
      form{
        width:100%;
        margin:0 auto;
        dl{
          width:100%;
          padding:28px 0 0;
          dt,dd{
            display:inline-block;
            vertical-align:middle;
          }
          dt{
            width:200px;
            font-size:17px;
            font-weight: 700;
            padding-left:2px;
            padding-top:10px;
          }
          dd{
            &:first-child{
              width:37px;
              text-align:center;
              padding-top:10px;
              span{
                display:inline-block;
                width:100%;
                height:21px;
                line-height:21px;
                border-radius:3px;
                font-size: 13px;
                color:#828282;
                text-align: center;
                background:#f6f6f6;
                &.required{
                  background:#ffdddd;
                  color:#ff5151;
                  border-radius:2px;
                }
              }
            }
            &:last-child{
              display:block;
              width:100%;
              font-size:16px;
              line-height:30px;
              margin-top:12px;
              input,textarea,select{
                background-color:#f5f5f5;
                width:100%;
                height:48px;
                border-radius:5px;
                padding-left:19px;
                letter-spacing:0.05em;
                &:focus{
                  border:1px solid #6ecae9;
                  background-color:#fafafa;
                  padding-left:18px;
                }
                &::placeholder {
                  color:#979797;
                }
              }
              textarea{
                height:240px;
                padding:13px 19px;
                resize:none;
                &:focus{
                  padding-left:18px;
                  padding-top:12px;
                }
              }
              select,.select{
                width:100%;
                cursor: pointer;
                background-image: url(../img/select.png);
                background-repeat: no-repeat;
                background-position: center right 5px;
                background-size: 36px auto;
                padding-right:45px;
                option{
                  background-color:#fafafa;
                  border:none;
                }
              }
              select{
                color: #979797;
                option{
                  color:#343434;
                }
              }
              &.checkWrap{
                margin-top:7px;
              }
              .checker{
                width:17px;
                height:17px;
                display:inline-block;
                vertical-align:middle;
                span{
                  display: inline-block;
                  width: 17px;
                  height: 17px;
                  border:2px solid #b4b4b4;
                  border-radius:1px;
                  vertical-align: top;
                  &.checked{
                    &:after{
                      content:"";
                      display:block;
                      width:16px;
                      height:100%;
                      background: url(../img/checkbox.png) no-repeat center center;
                      background-size: 16px auto;
                      left:3px;
                      top:-2px;
                      position:absolute;
                    }
                  }
                  input{
                    width:17px;
                    height:17px;
                    position:absolute;
                    top:0;
                    left:0;
                    z-index:1;
                  }
                }
              }
              label{
                vertical-align:middle;
                font-weight:700;
                cursor:pointer;
                padding-left:8px;
                letter-spacing:0.05em;
              }
            }
          }
        }
        ul{
          position: relative;
          width:100%;
          margin:50px 0 0;
          li{
            &.reset{
              margin-top:25px;
              text-align:center;
              input[type="button"]{
                color: #24386b;
                font-size: 16px;
                text-decoration: none;
                background-image:url(../img/back.png);
                background-repeat:no-repeat;
                background-position:center right 1px;
                background-size:18px auto;
                padding-right:25px;
                font-weight:700;
                &:hover{
                  opacity:0.8;
                }
              }
            }
            #image-btn{
              display: block;
              width: 100%;
              height: 62px;
              margin: 0px auto;
              border-radius: 7.5px;
              background-color: #d5ebf3;
              opacity: 1;
              color: #38aed5;
              font-size: 20px;
              font-weight:700;
              text-decoration: none;
              line-height: 62px;

            }
          }
        }
      }
    }
  }
  #PageContact.pageConfirm,
  #PageContact.pageThanks,
  #PageReservation.pageConfirm,
  #PageReservation.pageThanks {
    #MainImg,#TopicPath{
      background:transparent;
    }
    #Main{
      #ContBox02,.contBox{
        padding:55px 0 87px;
        .border_title{
          margin-bottom:46px;
        }
      }
      .contSubBox{
        h4{
          color:#38aed5;
          font-size:22px;
          font-weight:700;
          text-align:center;
          margin-bottom:10px;
        }
        p{
          font-size:16px;
          line-height:30px;
          text-align:center;
        }
      }
      form{
        margin-top:20px;
        dl{
          padding-top:18px;
          dd{
            &:last-of-type{
              margin-top:7px;
            }
          }
        }
      }
    }
  }

  /*
  お問い合わせ
  -------------------------------------*/
  #PageContact.pageIndex {
  }

  /*
  お問い合わせ（確認画面）
  -------------------------------------*/
  #PageContact.pageConfirm {
  }

  /*
  お問い合わせ（完了画面）
  -------------------------------------*/
  #PageContact.pageThanks {
  }

  /*
  体験コースお申し込み
  -------------------------------------*/
  #PageReservation.pageIndex {
    #Main {
      #ContBox01 {
        .formNotice {
          background-color: #fff;
          border-radius: 10px;
          padding: 51px 33px 56px;
          margin-top: 80px;
          h4 {
            font-size: 18px;
            font-weight: bold;
            color: #38aed5;
            line-height: 30px;
            margin-bottom: 30px;
          }
          p {
            text-align: justify;
            margin-bottom: 30px;
            &:last-of-type { margin-bottom: 0; }
          }
        }
      }
    }
  }

  /*
  体験コースお申し込み（確認画面）
  -------------------------------------*/
  #PageReservation.pageConfirm {
  }

  /*
  体験コースお申し込み（完了画面）
  -------------------------------------*/
  #PageReservation.pageThanks {
  }

  /*
  プライバシーポリシー
  -------------------------------------*/
  #PagePolicy.pageIndex {
    #MainTxt{
      padding:15px;
      #MainTxtInner{
        background-color:#f9f8f0;
        .innerBasic{
          background-image: url(../img/pagettl_r_w.png);
          background-size: auto 100%;
          background-repeat: no-repeat;
          background-position: center center;
          h2{
            text-align:center;
            line-height:210px;
            color:#38aed5;
            font-size:24px;
            font-weight: 700;
          }
        }
      }
    }
    #TopicPath{
      background:transparent;
    }
    #Main{
      margin:50px 0 50px;
      p{
        font-size:18px;
        line-height:32px;
        text-align:center;
        margin-bottom:53px;
        letter-spacing:0.08em;
      }
      .contBox{
        padding:35px 0 30px;
        border-top:1px solid #d5d5d5;
        h3{
          font-size:20px;
          color:#343434;
          font-weight:700;
          margin-bottom:13px;
          letter-spacing:0.05em;
          &:before{
            content:"";
            display:inline-block;
            vertical-align:middle;
            margin-right: 14px;
            width:20px;
            height:5px;
            background:#343434;
          }
        }
        p,ul li,dl{
          text-align:left;
          font-size:16px;
          line-height:30px;
          margin-bottom:0;
          letter-spacing:0.06em;
          &+p,&+dl{
            margin-top:16px;
          }
          a{
            color:#343434;
          }
        }
        ul{
          margin-top:0;
          margin-left:22px;
          li{
            position:relative;
            padding-left:9px;
            &:before{
              content: "";
              position: absolute;
              top: 0.7em;
              left: 0;
              display: block;
              border-radius: 50%;
              width: 4px;
              height: 4px;
              background: #343434;
            }
          }
        }
      }
    }
  }
}

@media print,screen and (min-width: 768px) {
      #PageAbout.pageIndex #Main #ContBox01 { height: 804px; }
      #PageAbout.pageIndex #Main #ContBox02 { height: 1792px; }
}
@media print,screen and (min-width: 376px) and (max-width: 767px)  {
      #PageAbout.pageIndex #Main #ContBox01 { min-height: 1348px; }
      #PageAbout.pageIndex #Main #ContBox02 { min-height: 2666px; }

}
@media print,screen and (max-width: 375px) {
      #PageAbout.pageIndex #Main #ContBox01 { min-height: 1368px; }
      #PageAbout.pageIndex #Main #ContBox02 { min-height: 2600px; }
}